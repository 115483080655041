/** @format */

import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { TResponseData } from "../../../types/api";
import { TFavProduct } from "../../../types/products";
import PaginationCom from "../../Common/PaginationComp";
import ProductList from "../ProductList";
import style from "./style.module.scss";

export default function FavComp({
  data,
  // total,
  saveFilters,
  filters,
  pageSize,
  setPageSize,
}: {
  data?: TResponseData<TFavProduct> | any;
  // total: number;
  saveFilters: (stateName: string, value: string) => void;
  filters: { page: string; search: string };
  pageSize: string;
  setPageSize: (size: string) => void;
}) {
  const { t } = useTranslation();
  if (!data) {
    return null;
  }
  return (
    <>
      <ProductList
        categoryId={data?.data?.[0]?.category_id}
        data={data?.data || []}
        total={data?.total}
        filters={filters}
        saveFilters={saveFilters}
      />

      <div className={clsx(style.paginationCont, "paginationCont")}>
        <PaginationCom
          setPageSize={setPageSize}
          setPage={(value: number) => saveFilters("page", value.toString())}
          currentPage={Number(filters.page)}
          total={data.total}
          perPage={pageSize}
        />
        <p>
          {t("show")} {(Number(filters.page) - 1) * Number(pageSize) + 1}{" "}
          {t("to")}{" "}
          {Number(filters.page) * Number(pageSize) > data.total
            ? data.total
            : Number(filters.page) * Number(pageSize)}{" "}
          {t("from")} {data.total} {t("card")}
        </p>
      </div>
    </>
  );
}
