import { TabsProps } from "antd";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { HiPlusSmall } from "react-icons/hi2";
import { useLoginUser } from "../../../hooks/profile";
import useAuthorization from "../../../hooks/useAuthorization";
import { TUser } from "../../../types/auth";
import CustomBtn from "../../Common/CustomBtn/CustomBtn";
import CustomTabs from "../../Common/CustomTabs/CustomTabs";
import DocumentsTable from "../DocumentsTable/DocumentsTable";
import styles from "./Tabs.module.scss";
import "./_antd.scss";

type TProps = {
  data?: TUser;
  isLoading: boolean;
};
function ProfileTabs({ data, isLoading }: TProps) {
  const { isApproved } = useLoginUser();
  const { hasPermission } = useAuthorization();
  const { t } = useTranslation();
  const items: TabsProps["items"] = [
    {
      key: "docs",
      label: t("documents"),
      children: (
        <DocumentsTable isLoading={isLoading} data={data?.attachments} />
      ),
    },
  ];

  return (
    <div className={clsx("profileTabs")}>
      <CustomTabs
        tabBarExtraContent={
          <>
            {isApproved && hasPermission("WalletAddBalance") ? (
              <CustomBtn
                href="/charge-wallet"
                icon={<HiPlusSmall />}
                variant="filled"
                size="large"
              >
                {t("add_balance")}
              </CustomBtn>
            ) : null}
          </>
        }
        rootClassName={styles.tabs}
        defaultActiveKey={"docs"}
        items={items}
      />
    </div>
  );
}

export default ProfileTabs;
