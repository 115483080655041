/** @format */
"use client";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Checkbox, Input, Select } from "antd";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useGetAllCurrencies } from "../../../hooks/lookups";
import { useGetProfile } from "../../../hooks/profile";
import { useCashReceipt, useGetBanks } from "../../../hooks/useCash";
import { TBanks } from "../../../types/products";

import ImageUploader from "../../Common/ImageUploader";
import style from "./style.module.scss";
const schema = yup.object().shape({
  bank_id: yup.number().required("Bank is required"),
  transferring_name: yup.string().required("Name is required"),
  amount: yup
    .number()
    .typeError("Total must be a number")
    .positive("Total must be greater than zero")
    .required("Total is required"),
  transferring_account_number: yup
    .number()
    .typeError("Account Number must be a number")
    .positive("Account Number must be greater than zero")
    .required("Account Number is required"),
  notes: yup.string(),
  currency_id: yup.number().required("Currency is required"),
  receipt_image: yup
    .mixed()
    .required("You need to upload a file")
    .test(
      "يجب أن يكون receipt image ملف من النوع: jpeg, png, jpg, pdf.",
      (value) => {
        return (
          value &&
          value instanceof File &&
          ["image/jpeg", "image/png", "image/jpg", "image/pdf"].includes(
            value.type
          )
        );
      }
    ),
});
const { TextArea } = Input;

export default function Cash() {
  const navigate = useNavigate();
  const [selectedBank, setBank] = useState<TBanks>();
  const [confirmation, setConfirmation] = useState(false);
  const [receipt_image, setImage] = useState<string | null>(null);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { t } = useTranslation();
  const { data: currencies } = useGetAllCurrencies();
  const { data: profile } = useGetProfile();
  const { data: banks } = useGetBanks();
  const { data, mutate, isLoading } = useCashReceipt(navigate);
  const onSubmit = (data: any) => {
    if (confirmation) {
      const formData = new FormData();

      Object.keys(data)?.forEach((key: any) => {
        formData.append(key, data[key]);
      });
      formData.append("recharge_balance_type", "cash");

      formData.append("notes", data.notes || "");
      mutate(formData);
    }
  };
  const saveImage = (image: string | null, file: File) => {
    setValue("receipt_image", file);
    setImage(image);
  };
  return (
    <div className={style.cont}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.cashCont}>
          <div className={style.formCont}>
            <div className={style.form}>
              <div>
                <label>{t("bank")}</label>

                <Controller
                  name='bank_id'
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder={t("choose_bank")}
                      size='large'
                      {...field}
                      onChange={(bankId) => {
                        setBank(
                          banks?.find((bank) => Number(bankId) === bank.id)
                        );
                        setValue("bank_id", bankId);
                      }}
                      style={{ width: "100%" }}
                      options={[
                        {
                          label: <span> {t("all_sections")} </span>,
                          title: t("choose_bank"),
                          options: banks
                            ? banks.map((brand) => ({
                                label: <span>{brand.name}</span>,
                                value: brand.id,
                              }))
                            : [],
                        },
                      ]}
                      showSearch
                    />
                  )}
                />
                {errors.bank_id?.message ? (
                  <span className={style.error}>{errors.bank_id?.message}</span>
                ) : null}
              </div>
              <div>
                <label>{t("bank_account_details")}</label>
                <div className={style.bankDetails}>
                  <span>
                    {" "}
                    {t("account_name")} : {selectedBank?.name || "-"}
                  </span>
                  <span>
                    {t("account_number")} :{" "}
                    {selectedBank?.account_number || "-"}
                  </span>
                  <span>
                    {t("account_details")}: {selectedBank?.description || "-"}
                  </span>
                  <span>IBAN : {selectedBank?.iban_number || "-"}</span>
                </div>
              </div>
              <div>
                <label>{t("senderName")} </label>
                <Controller
                  name='transferring_name'
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder={t("enter_sender_name")}
                      size='large'
                      {...field}
                    />
                  )}
                />
                {errors.transferring_name?.message ? (
                  <span className={style.error}>
                    {errors.transferring_name?.message}
                  </span>
                ) : null}
              </div>
              <div>
                <label>{t("sender_account_num")}</label>
                <Controller
                  name='transferring_account_number'
                  control={control}
                  render={({ field }) => (
                    <Input
                      type='number'
                      size='large'
                      {...field}
                      placeholder={t("enter_account_num")}
                      // addonAfter={profile?.currency?.name || " ريال سعودي"}
                      // type='transferring_account_number'
                    />
                  )}
                />
                {errors.amount?.message ? (
                  <span className={style.error}>{errors.amount?.message}</span>
                ) : null}
              </div>
              <div>
                <label>{t("balance_amount")}</label>
                <Controller
                  name='amount'
                  control={control}
                  render={({ field }) => (
                    <Input
                      size='large'
                      {...field}
                      placeholder={t("enter_balance_amount")}
                      // addonAfter={profile?.currency?.name || " ريال سعودي"}
                      type='number'
                    />
                  )}
                />
                {errors.amount?.message ? (
                  <span className={style.error}>{errors.amount?.message}</span>
                ) : null}
              </div>
              <div>
                <label>{t("currency")}</label>
                <Controller
                  name='currency_id'
                  control={control}
                  render={({ field }) => (
                    <Select
                      onChange={(event) => setValue("currency_id", event)}
                      placeholder={t("choose_currency")}
                      options={currencies?.map((currency) => ({
                        label: currency?.name,
                        value: currency.id,
                      }))}
                    />
                  )}
                />
                {errors.transferring_name?.message ? (
                  <span className={style.error}>
                    {errors.transferring_name?.message}
                  </span>
                ) : null}
              </div>
              <div>
                <label>{t("additional_notes")}</label>
                <Controller
                  name='notes'
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      {...field}
                      maxLength={100}
                      placeholder={t("enter_notes")}
                    />
                  )}
                />
              </div>
              <Checkbox
                value={confirmation}
                onChange={() => setConfirmation(!confirmation)}>
                {t("transfer_confirmation")}{" "}
              </Checkbox>
            </div>
          </div>
          <div className={style.receiptCont}>
            <p>{t("receipt_image")}</p>
            <ImageUploader setImage={saveImage} image={receipt_image} />
            {errors.receipt_image?.message ? (
              <span className={style.error}>
                {errors.receipt_image?.message}
              </span>
            ) : null}
          </div>
        </div>
        <Button
          loading={isLoading}
          variant='filled'
          htmlType='submit'
          color='default'>
          {t("send")}
        </Button>
      </form>
    </div>
  );
}
