/** @format */

import { Player } from "@lordicon/react";
import { DatePicker, Input, Select } from "antd";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FaRegFilePdf } from "react-icons/fa";
import { LuSearch } from "react-icons/lu";
import { RiFileExcel2Line } from "react-icons/ri";
import * as XLSX from "xlsx";
import CardsNumber from "../../../assets/lottie/cards-num.json";
import Coin from "../../../assets/lottie/coin.json";
import Tax from "../../../assets/lottie/tax.json";
import TotalWithTax from "../../../assets/lottie/total-with-tax.json";
import { TBrand } from "../../../types/store";
import styles from "./ReportsActions.module.scss";

const { RangePicker } = DatePicker;
type TProps = {
  activeTab: string;
  onSearch: (value: string) => void;
  onSelectDate: (startData: string, endData: string) => void;
  onSelectType: (type: string) => void;
  onSelectBrand: (brandId: number) => void;
  data: any;
  brands: {
    data: TBrand[];
    isBrandsLoading: Boolean;
  };
  stats: Partial<{
    tax: number;
    total: number;
    totalAfterTax: number;
    cardsNumber: number;
  }>;
};
function ReportsActions({
  activeTab,
  onSearch,
  onSelectType,
  onSelectDate,
  onSelectBrand,
  stats,
  brands,
  data,
}: TProps) {
  const playerRef1 = useRef<Player>(null);
  const playerRef2 = useRef<Player>(null);
  const playerRef3 = useRef<Player>(null);
  const { t } = useTranslation();
  useEffect(() => {
    playerRef1.current?.playFromBeginning();
    playerRef2.current?.playFromBeginning();
    playerRef3.current?.playFromBeginning();
  }, [activeTab]);
  const displayedBrands = brands.data?.map((brand) => ({
    value: brand.id,
    label: brand.name,
  }));
  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableData = data.map((row: any) => Object.values(row));
    const tableHeaders = Object.keys(data[0]);
    doc.text("Excel Data Export", 10, 10);
    doc.autoTable({
      head: [tableHeaders],
      body: tableData,
    });

    doc.save("file.pdf"); // Save as PDF
  };
  const handleExport = () => {
    // Sample data to be added to the Excel sheet

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert JSON data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate Excel file and save it
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Use file-saver to save the file
    const file = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(file, activeTab + "-reports.xlsx");
  };
  const formateDate = (date: Date) => {
    const today = new Date(date.toString());
    const day = today.getDate();
    const month = String(today.getUTCMonth() + 1).padStart(2, "0");
    const year = today.getUTCFullYear();
    return `${year}-${month}-${day}`;
  };
  return (
    <div className={styles.reportsActions}>
      <div className={styles.filters}>
        <div className={styles.inputs}>
          <RangePicker
            style={{ width: "450px" }}
            lang={"ar"}
            placeholder={[t("from"), t("to")]}
            maxDate={dayjs()}
            onChange={(dates: any) =>
              dates?.length
                ? onSelectDate(formateDate(dates[0]), formateDate(dates[1]))
                : onSelectDate("", "")
            }
          />
          <Input
            onChange={(e) => onSearch(e.target.value)}
            className={styles.searchInput}
            prefix={<LuSearch />}
            placeholder={t("search")}
          />
          {activeTab === "ordersDetails" && (
            <Select
              onChange={onSelectType}
              placeholder={t("select_order_type")}
              options={[
                { value: "topup", label: t("topup") },
                { value: "serial", label: t("serial") },
              ]}
            />
          )}

          {activeTab === "products" && (
            <Select
              allowClear
              showSearch
              style={{ minWidth: "20rem" }}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={onSelectBrand}
              placeholder={t("select_brand")}
              options={displayedBrands}
              loading={!!brands?.isBrandsLoading}
            />
          )}
        </div>
        <div className={styles.export}>
          <b>{t("export")} :</b>
          <div className={styles.exportType} onClick={exportToPDF}>
            <span>PDF</span>
            <FaRegFilePdf />
          </div>
          <div className={styles.exportType} onClick={handleExport}>
            <span>Excel</span>
            <RiFileExcel2Line />
          </div>
        </div>
      </div>
      <div className={styles.divider} />
      {activeTab === "products" && (
        <ul className={styles.stats}>
          <li>
            <Player
              onComplete={() => playerRef1.current?.playFromBeginning()}
              size={55}
              ref={playerRef1}
              icon={Coin}
              colors="primary:#6691e7,secondary:#fb8b02"
            />
            <b className={styles.total}>{stats.total}</b>&nbsp;
            {/* <span>ر.س</span> */}
            <p>{t("total_price")}</p>
          </li>
          <li>
            <Player
              onComplete={() => playerRef2.current?.playFromBeginning()}
              size={55}
              ref={playerRef2}
              icon={Tax}
              colors="primary:#6691e7,secondary:#fb8b02"
            />
            <b className={styles.total}>{stats.tax}</b>
            <p>{t("total_tax")}</p>
          </li>
          <li>
            <Player
              onComplete={() => playerRef3.current?.playFromBeginning()}
              size={55}
              ref={playerRef3}
              icon={TotalWithTax}
              colors="primary:#6691e7,secondary:#fb8b02"
            />
            <b className={styles.total}>{stats.totalAfterTax} </b>&nbsp;
            {/* <span>ر.س</span> */}
            <p>{t("total_after_tax")}</p>
          </li>
        </ul>
      )}
      {activeTab === "orders" && (
        <ul className={styles.stats}>
          <li>
            <Player
              onComplete={() => playerRef1.current?.playFromBeginning()}
              size={55}
              ref={playerRef1}
              icon={CardsNumber}
              colors="primary:#6691e7,secondary:#fb8b02"
            />
            <b className={styles.total}>{stats.cardsNumber}</b>&nbsp;
            <p>{t("card_no")} </p>
          </li>
          <li>
            <Player
              onComplete={() => playerRef1.current?.playFromBeginning()}
              size={55}
              ref={playerRef1}
              icon={TotalWithTax}
              colors="primary:#6691e7,secondary:#fb8b02"
            />
            <b className={styles.total}>{stats.total} </b>&nbsp;
            <p>{t("total_price")}</p>
          </li>
        </ul>
      )}
    </div>
  );
}

export default ReportsActions;
