/** @format */

import { useQuery } from "react-query";
import { TProductFilters } from "../components/Products";
import { productService } from "../services/products";
import { tags } from "../services/tags";
const getProducts = async (filters?: TProductFilters) => {
  const {
    data: { data },
  } = await productService.getProducts(filters || {});
  return data;
};

const searchProducts = async (search: string) => {
  const {
    data: { data },
  } = await productService.searchProducts(search);
  return data;
};
export const useGetProducts = (filters: TProductFilters) => {
  const mutation = useQuery(
    [tags.getProducts, filters.page, filters.pageSize, filters.searchValue],
    () => getProducts(filters)
  );
  return { ...mutation };
};
export const useFavProducts = () => {
  const mutation = useQuery(tags.getFavProducts, () => getProducts());
  return { ...mutation };
};
export const useSearchProducts = (search: string) => {
  const mutation = useQuery(
    [tags.getSearchProducts, search],
    () => searchProducts(search),
    {
      retry: false,
      enabled: search.length >= 3,
    }
  );
  return { ...mutation };
};
const getHome = async () => {
  const {
    data: { data },
  } = await productService.getHome();
  return data;
};
export const useGetHome = () => {
  const mutation = useQuery(tags.getHome, () => getHome());
  return { ...mutation };
};
