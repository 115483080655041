/** @format */
import { ConfigProvider, Pagination, PaginationProps } from "antd";
import { useTranslation } from "react-i18next";
const PaginationCom = ({
  total,
  currentPage,
  setPage,
  perPage,
  setPageSize,
}: {
  total: number;
  currentPage: number;
  setPage: (page: number) => void;
  perPage?: string;
  setPageSize?: (per_page: string) => void;
}) => {
  const { t } = useTranslation();
  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <button>{t("prev")}</button>;
    }
    if (type === "next") {
      return <button>{t("next")}</button>;
    }
    return originalElement;
  };
  return (
    <ConfigProvider
      theme={{
        components: {
          Pagination: {
            itemActiveBg: "cornflowerBlue !important",

            /* here is your component tokens */
          },
        },
      }}>
      <Pagination
        onShowSizeChange={(current, size) =>
          setPageSize && setPageSize(size.toString())
        }
        showSizeChanger={!!perPage}
        pageSizeOptions={[10, 20, 30, 40]}
        onChange={(page) => setPage(page)}
        current={currentPage}
        hideOnSinglePage
        pageSize={Number(perPage || 10)}
        total={total}
        itemRender={itemRender}
      />
    </ConfigProvider>
  );
};
export default PaginationCom;
