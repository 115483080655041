/** @format */

import { useEffect, useState } from "react";
import {
  useGetAllOrderProductsReport,
  useGetAllOrdersReport,
  useGetAllReportProducts,
  useGetOrderProductsReport,
  useGetOrdersReport,
  useGetReportProducts,
} from "../../hooks/reports";
import { useAllBrands } from "../../hooks/store";
import { TReportParams } from "../../types/api";
import styles from "./index.module.scss";
import Orders from "./Orders/Orders";
import OrdersDetails from "./OrdersDetails/OrdersDetails";
import ProductSalesTable from "./ProductSalesTable/ProductSalesTable";
import ReportsActions from "./ReportsActions/ReportsActions";
import ReportsTab from "./ReportsTab/ReportsTab";
function ReportsContent() {
  const [params, setParams] = useState<TReportParams>({});
  const [activeTab, setActiveTab] = useState("products");

  const {
    data: products,
    isLoading: isProductsLoading,
    refetch: refetchProducts,
  } = useGetReportProducts({ ...params, search: params.search });
  const { data: allProducts } = useGetAllReportProducts({
    ...params,
    search: params.search,
  });
  const {
    data: orderDetails,
    isLoading: isOrderDetailsLoading,
    refetch: refetchOrderDetails,
  } = useGetOrderProductsReport(params);
  const { data: allOrderDetails } = useGetAllOrderProductsReport({
    ...params,
    search: params.search,
  });
  const { data: brands, isLoading: isBrandsLoading } = useAllBrands();

  const {
    data: orders,
    isLoading: isOrdersLoading,
    refetch: refetchOrders,
  } = useGetOrdersReport(params);
  const { data: allOrder } = useGetAllOrdersReport({
    ...params,
    search: params.search,
  });
  useEffect(() => {
    setParams(() => ({}));
  }, [activeTab]);

  useEffect(() => {
    if (activeTab === "products") refetchProducts();
    if (activeTab === "orders") refetchOrders();
    if (activeTab === "ordersDetails") refetchOrderDetails();
  }, [params]);

  const handlePagination = (page: number) => {
    setParams((prev) => ({ ...prev, page }));
  };

  const handleSelectDate = (startData: string, endData: string) => {
    setParams((prev) => ({
      ...prev,
      date_from: startData,
      date_to: endData,
      page: 1,
    }));
  };
  const handleSearch = (name: string) => {
    setParams((prev) => ({ ...prev, search: name, page: 1 }));
  };

  const handleType = (type: string) => {
    setParams((prev) => ({ ...prev, type, page: 1 }));
  };

  const handleBrandChange = (brand_id: number) => {
    setParams((prev) => ({ ...prev, brand_id, page: 1 }));
  };

  const stats = {
    products: {
      tax: Number(products?.totals?.total_tax?.toFixed(2)),
      total: Number(products?.totals?.total_price?.toFixed(2)),
      total_after_tax: Number(products?.totals?.total_after_tax?.toFixed(2)),
      cardsCount: 0,
    },
    orders: {
      tax: 0,
      total_after_tax: 0,
      total: Number(orders?.data?.totals?.totalOrders?.toFixed(2)),
      cardsCount: Number(orders?.data?.totals?.totalProductsCount),
    },
  };
  return (
    <div className={styles.reportsContent}>
      <ReportsTab active={activeTab} setActive={setActiveTab} />
      <div className={styles.reportsTable}>
        <ReportsActions
          brands={{
            data: brands,
            isBrandsLoading,
          }}
          key={activeTab}
          onSearch={handleSearch}
          onSelectDate={handleSelectDate}
          onSelectType={handleType}
          onSelectBrand={handleBrandChange}
          activeTab={activeTab}
          data={
            activeTab === "products"
              ? allProducts?.data
              : activeTab === "orders"
              ? allOrder?.data?.data
              : allOrderDetails
          }
          stats={{
            tax: stats[activeTab as keyof typeof stats]?.tax || 0,
            total: stats[activeTab as keyof typeof stats]?.total || 0,
            totalAfterTax:
              stats[activeTab as keyof typeof stats]?.total_after_tax || 0,
            cardsNumber:
              stats[activeTab as keyof typeof stats]?.cardsCount || 0,
          }}
        />
        {activeTab === "products" && (
          <ProductSalesTable
            data={products?.data}
            handlePagination={handlePagination}
            isLoading={isProductsLoading}
          />
        )}
        {activeTab === "orders" && (
          <Orders
            data={orders?.data?.data}
            handlePagination={handlePagination}
            isLoading={isOrdersLoading}
          />
        )}

        {activeTab === "ordersDetails" && (
          <OrdersDetails
            data={orderDetails}
            handlePagination={handlePagination}
            isLoading={isOrderDetailsLoading}
          />
        )}
      </div>
    </div>
  );
}

export default ReportsContent;
