import { Input } from "antd";
import { useTranslation } from "react-i18next";
import { CiSearch } from "react-icons/ci";
import { HiPlusSmall } from "react-icons/hi2";
import { debounce } from "../../../utils/debounce";
import CustomBtn from "../CustomBtn/CustomBtn";
import styles from "./SectionActions.module.scss";

type TSectionActions = {
  search: {
    placeholder?: string;
    onChange: (val: string) => void;
  };
  button?: {
    href?: string;
    value: string;
  };
};
function SectionActions({ search, button }: TSectionActions) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const handleSearchChange = debounce(
    (event) => search.onChange(event.target.value),
    500
  );
  return (
    <div className={styles.sectionActions}>
      <Input
        className={styles.searchInput}
        onChange={handleSearchChange}
        prefix={<CiSearch />}
        placeholder={search.placeholder || t("search")}
      />
      {button && (
        <CustomBtn
          href={`/${language}${button?.href}`}
          icon={<HiPlusSmall />}
          rootClassName={styles.actionBtn}
          type="primary"
        >
          {button?.value}
        </CustomBtn>
      )}
    </div>
  );
}

export default SectionActions;
