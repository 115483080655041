/** @format */

import { useTranslation } from "react-i18next";
import SignUpLottie from "../../../assets/lottie/lottie-sign-up.json";
import AuthLayout from "../../../components/auth/AuthLayout/AuthLayout";
import GoogleAuth from "../../../components/auth/Forms/GoogleAuth/GoogleAuth";

function Page() {
  const { t } = useTranslation();
  document.title = t("google_auth");
  return (
    <AuthLayout lottie={SignUpLottie}>
      <GoogleAuth />
    </AuthLayout>
  );
}
export default Page;
