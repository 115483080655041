/** @format */

import { Form, Input, Select } from "antd";
import FormItem from "antd/es/form/FormItem";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaUserTie } from "react-icons/fa";
import { IoMailUnreadOutline } from "react-icons/io5";
import { PhoneInput } from "react-international-phone";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCreateEmployee,
  useGetEmployeeDetails,
  useUpdateEmployee,
} from "../../../hooks/employee";
import { useGetRoles } from "../../../hooks/roles";
import handleFormData from "../../../utils/handleFormData";
import Validation from "../../../validation/antDesign";
import ImageUploader from "../../Common/ImageUploader";
import SectionCard from "../../Common/SectionCard/SectionCard";
import CustomSkelton from "../../CustomSkelton/CustomSkelaton";
import FormActionButtons from "../../FormActionButtons/FormActionButtons";
import PermissionsCategories from "../PermissionsCategories/PermissionsCategories";
import styles from "./EmployeeForm.module.scss";

function EmployeeForm() {
  //react router
  const { id } = useParams();
  const navigate = useNavigate();
  //  variables
  const [form] = Form.useForm();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isEdit = !!id;

  //react query
  const { data: roles, isLoading: rolesLoading } = useGetRoles();
  const {
    data: employeeDetails,
    isLoading: employeeLoading,
    isFetching: employeeFetching,
    isSuccess: employeeSuccess,
    refetch: employeeRefetch,
  } = useGetEmployeeDetails(Number(id));
  const { mutate: addEmployee, isLoading: addEmployeeLoader } =
    useCreateEmployee(navigate);

  const { mutate: updateEmployee, isLoading: updateEmployeeLoader } =
    useUpdateEmployee(navigate);

  const [employeePhoto, setEmployeePhoto] = useState("");
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
  const [inputPhoneHasError, setInputPhoneHasError] = useState(false);
  const [countryCode, setCountryCode] = useState("");

  useEffect(() => {
    if (isEdit && employeeSuccess) {
      setEmployeePhoto(employeeDetails?.logo || "");
      form.setFieldsValue({
        ...employeeDetails,
        logo: undefined,
        roles: employeeDetails?.roles?.[0],
        permissions: employeeDetails?.permissions?.map((p) => p.name),
      });
      setSelectedPermissions(employeeDetails?.permissions?.map((p) => p.name));
    }
  }, [employeeFetching]);

  useEffect(() => {
    if (id) {
      employeeRefetch();
    }
  }, [id]);

  const handleEmployeeChange = (base64: string | null, file: File) => {
    if (base64) setEmployeePhoto(base64);
    form.setFieldValue("logo", file);
  };
  const getSelectedPermissions = (roleId: string) => {
    const rolePermissions = roles?.find((item) => item.name === roleId);
    const permissions = rolePermissions?.permissions?.map((p) => p.name);
    if (permissions) setSelectedPermissions(permissions);

    console.log(permissions);
  };

  const handleSubmitForm = (values: any) => {
    const formData = handleFormData({
      ...values,
      role: values.role || values.roles,
      owner_name: "temp",
    });

    if (isEdit) {
      updateEmployee({ body: formData, id: Number(id) });
    } else {
      addEmployee(formData);
    }
  };

  const formID = "employeeForm";
  const rolesOptions = roles?.map((item) => ({
    label: item.name,
    value: item.name,
  }));

  document.title = isEdit ? t("edit_employee") : t("add_new_employee");

  if (employeeLoading) return <CustomSkelton />;

  console.log(form.getFieldError(["phone"]));
  return (
    <Form
      id={formID}
      className={styles.employeeForm}
      form={form}
      onFinish={(values) => handleSubmitForm(values)}
    >
      <div className={styles.header}>
        <h3>{isEdit ? t("edit_employee") : t("add_new_employee")}</h3>{" "}
        <FormActionButtons
          formID={formID}
          className={styles.formActions}
          onClickCancel={() =>
            navigate(`/${language}/settings/employees#employees`)
          }
          loading={addEmployeeLoader || updateEmployeeLoader}
        />
      </div>
      <div className={styles.content}>
        <SectionCard
          title={t("employee_details")}
          rootClassName={styles.sectionCardForm}
        >
          <div className={styles.form}>
            <Form.Item
              rootClassName={styles.inputGroup}
              layout="vertical"
              label={t("employee_name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: `     ${t("employee_name")}  ${t("required")}`,
                },
              ]}
            >
              <Input placeholder={`${t("write")}  ${t("employee_name")}`} />
            </Form.Item>
            {/* <Form.Item
              rootClassName={styles.inputGroup}
              layout="vertical"
              label=" مالك الشركة/ المدير المسئول"
              name="owner_name"
              rules={[
                {
                  required: true,
                  message: " مالك الشركة/ المدير المسئول مطلوب",
                },
              ]}
            >
              <Input placeholder="ادخل  مالك الشركة/ المدير المسئول" />
            </Form.Item> */}
            <Form.Item
              rootClassName={styles.inputGroup}
              layout="vertical"
              label={t("role")}
              name={isEdit ? "roles" : "role"}
              rules={[
                { required: true, message: ` ${t("role")}  ${t("required")}` },
              ]}
            >
              <Select
                // defaultValue={employeeDetails?.roles?.[0]}
                style={{ width: "100%" }}
                placeholder={`${t("select")} ${t("role")}  `}
                options={rolesOptions}
                loading={rolesLoading}
                onChange={(val) => {
                  getSelectedPermissions(val);
                }}
              />
            </Form.Item>
            {/* <Form.Item
              rootClassName={styles.inputGroup}
              layout="vertical"
              label=" الهاتف"
              name="phone"
              rules={[{ required: true, message: "     الهاتف  مطلوب" }]}
            >
              <Input placeholder="اكتب رقم الهاتف" />
            </Form.Item> */}
            <Form.Item
              rootClassName={clsx(
                styles.inputGroup,
                styles.phoneInput,
                inputPhoneHasError && styles.error
              )}
              layout="vertical"
              label={t("phone")}
              name="phone"
              rules={[
                { required: true, message: `${t("phone")}  ${t("required")}` },
              ]}
            >
              <PhoneInput
                name="phone"
                value={form.getFieldValue("phone")}
                defaultCountry="sa"
                onChange={(value, { country, inputValue }) => {
                  setCountryCode(country.dialCode);
                  form.setFieldValue("phone", value);
                }}
              />
            </Form.Item>

            <Form.Item
              rootClassName={styles.inputGroup}
              layout="vertical"
              label={t("email")}
              name="email"
              rules={[
                { required: true, message: ` ${t("email")} ${t("required")}` },
              ]}
            >
              <Input
                suffix={<IoMailUnreadOutline />}
                type="email"
                autoComplete="off"
                placeholder={`${t("write")}  ${t("email")}`}
              />
            </Form.Item>
            <Form.Item
              rootClassName={styles.inputGroup}
              layout="vertical"
              label={t("password")}
              name="password"
              rules={isEdit ? [] : Validation.password(t)}
            >
              <Input.Password
                autoComplete="off"
                placeholder={` ${t("write")}  ${t("password")}`}
              />
            </Form.Item>
            <Form.Item
              rootClassName={styles.inputGroup}
              layout="vertical"
              label={t("confirm_password")}
              name="password_confirmation"
              rules={isEdit ? [] : Validation.confirmPassword(t)}
            >
              <Input.Password
                placeholder={` ${t("write")}  ${t("password")}`}
              />
            </Form.Item>
            <FormItem hidden name="logo" />
            {employeeDetails?.permissions?.length && (
              <PermissionsCategories
                defaultPermissions={selectedPermissions}
                getPermissions={(permissions) => {
                  form.setFieldValue("permissions", permissions);
                }}
              />
            )}
          </div>
        </SectionCard>
        <div className={styles.employeePhoto}>
          <SectionCard
            rootClassName={styles.sectionCardForm}
            title={t("personal_photo")}
          >
            <div className={styles.imgUploaderContainer}>
              <ImageUploader
                addImageBtnClassName={styles.imgUploaderBtn}
                avatarIcon={<FaUserTie />}
                className={styles.imgUploader}
                setImage={handleEmployeeChange}
                image={employeePhoto}
              />
            </div>
          </SectionCard>
        </div>
      </div>
      <FormItem
        name="permissions"
        // rules={[{ required: true, message: " الصلاحيات مطلوبة" }]}
      />

      {/* {employeeDetails?.permissions?.length && (
        <PermissionsTable
          defaultPermissions={employeeDetails?.permissions?.map((p) => p.name)}
          getPermissions={(permissions) => {
            if (permissions.length) {
              form.setFieldValue("permissions", permissions);
            }
          }}
        />
      )} */}
    </Form>
  );
}

export default EmployeeForm;
