/** @format */

"use client";
import { Form, Input } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useResetPassword } from "../../../../hooks/auth";
import Validation from "../../../../validation/antDesign";
import CustomBtn from "../../../Common/CustomBtn/CustomBtn";
import styles from "../Forms.module.scss";

function ResetPasswordForm({ formHeading }: { formHeading: string }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { mutate, isLoading } = useResetPassword(navigate);
  const [searchParams] = useSearchParams();
  console.log(searchParams);

  useEffect(() => {
    if (!searchParams.has("email")) {
      navigate("/auth/forget-password");
    }
  }, [searchParams]);

  return (
    <Form
      onFinish={(values) =>
        mutate({ ...values, email: searchParams.get("email") })
      }
      rootClassName={styles.form}
    >
      <h2 className={styles.formHeading}>{formHeading}</h2>

      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label={t("verification_code")}
        name="otp"
        rules={[
          {
            required: true,
            message: `  ${t("verification_code")} ${t("required")}`,
          },
        ]}
      >
        <Input
          type="number"
          placeholder={` ${t("enter")}  ${t("verification_code")}`}
        />
      </Form.Item>

      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label={t("password")}
        name="password"
        rules={Validation.password(t)}
      >
        <Input.Password
          autoComplete="off"
          placeholder={` ${t("write")}  ${t("password")}`}
        />
      </Form.Item>

      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label={t("confirm_password")}
        name="password_confirmation"
        rules={Validation.confirmPassword(t)}
      >
        <Input.Password placeholder={` ${t("write")}  ${t("password")}`} />
      </Form.Item>

      <CustomBtn
        disabled={isLoading}
        loading={isLoading}
        rootClassName={styles.submit}
        type="primary"
        htmlType="submit"
      >
        {t("save")}
      </CustomBtn>
    </Form>
  );
}
export default ResetPasswordForm;
