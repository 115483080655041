/** @format */

import { useTranslation } from "react-i18next";
import { THome } from "../../../types/products";
import Box from "../../Common/Box";
import style from "./style.module.scss";
import ExportTickets from "./Tickets";
import TransactionsTable from "./TransactionsTable";

export default function RechargeAndTickets({ data }: { data: THome }) {
  const { t } = useTranslation();
  return (
    <div className={style.cont}>
      <Box title={t("pendingRecharge")}>
        <TransactionsTable transactions={data.wallet_cash_transactions} />
      </Box>
      <Box title={t("pendingComplaints")}>
        <ExportTickets data={data.support_tickets} />
      </Box>
    </div>
  );
}
