/** @format */

export const tags = {
  getProfile: "GET_PROFILE",
  getEmployees: "GET_EMPLOYEES",
  getCountries: "GET_COUNTRIES",
  getCities: "GET_CITIES",
  getRegions: "GET_REGIONS",
  getPermissions: "GET_PERMISSIONS",
  getRoles: "GET_ROLES",
  getCart: "GET_CARTS",
  getCategoryProducts: "GET_CATEGORY_PRODUCTS",
  getFavProd: "GET_FAV_PROD",
  getEmployee: "GET_EMPLOYEE",
  getRole: "GET_ROLE",
  getOrders: "GET_ORDERS",
  getOrder: "GET_ORDER",
  getAllSettings: "GET_ALL_SETTINGS",
  getBalance: "GET_BALANCE",
  getHome: "GET_HOME",
  getCurrencies: "GET_CURRENCIES",
  getProducts: "GET_PRODUCTS",
  getFavProducts: "GET_FAV_PRODUCTS",
  getSearchProducts: "GET_SEARCH_PRODUCTS",
  getTickets: "GET_TICKETS",
  getNotification: "GET_NOTIFICATION",
  readNotification: "Read_NOTIFICATION",
  getNotificationCount: "GET_NOTIFICATION_COUNT",
  getReportsProducts: "GET_REPORTS_PRODUCTS",
  getReportsOrders: "GET_REPORTS_ORDERS",
  getReportsOrderDetails: "GET_REPORTS_ORDER_DETAILS",
};
