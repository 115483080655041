/** @format */

import { AxiosResponse } from "axios";
import { TPaginationResponse, TResponse } from "../types/api";
import {
  TCity,
  TCountry,
  TNotification,
  TPermission,
  TRegion,
  TSaveTokenPayload,
  TSettingsResponse,
} from "../types/TLookups";
import api from "./api";

const lookupsServices = {
  getAllCountries: async (): Promise<AxiosResponse<TResponse<TCountry[]>>> =>
    api.get("/countries"),

  getAllRegions: async (): Promise<AxiosResponse<TResponse<TRegion[]>>> =>
    api.get("/regions"),
  getAllNotification: async (): Promise<
    AxiosResponse<TPaginationResponse<TNotification[]>>
  > => api.get("/notifications"),
  getAllNotificationCount: async (): Promise<
    AxiosResponse<AxiosResponse<number>>
  > => api.get("/notifications/count"),
  readAllNotification: async (
    notificationId: number
  ): Promise<AxiosResponse<AxiosResponse<number>>> =>
    api.get("/notifications/read/" + notificationId),

  saveToken: async (
    payload: TSaveTokenPayload
  ): Promise<AxiosResponse<AxiosResponse<number>>> =>
    api.post("/notificationTokens/firebase/store", payload),
  getAllCities: async (): Promise<AxiosResponse<TResponse<TCity[]>>> =>
    api.get("/cities"),
  getAllPermissions: async (): Promise<
    AxiosResponse<TResponse<TPermission[]>>
  > => api.get("/permissions"),
  getAllSettings: async (): Promise<
    AxiosResponse<TResponse<TSettingsResponse>>
  > => api.get("/main-settings"),
};

export default lookupsServices;
