/** @format */
import { useTranslation } from "react-i18next";
import { IoCopyOutline } from "react-icons/io5";
import QRCode from "react-qr-code";
import CustomBtn from "../../../Common/CustomBtn/CustomBtn";
import CustomLink from "../../../Common/CustomLink/CustomLink";
import style from "./style.module.scss";

export default function GoogleAuth() {
  const { t } = useTranslation();
  return (
    <div className={style.googleAuthCont}>
      <div>
        <h2 className={style.formHeading}>{t("google_auth")}</h2>
        <p className={style.msg}>
          {t("you_will_need")} <span> Google Authenticator</span>{" "}
          {t("to_complete_this_proccess")}
        </p>
      </div>
      <div className={style.qrCont}>
        <QRCode
          value={sessionStorage.getItem("qrCodeUrl") || ""}
          size={150}
          style={{ height: "auto", margin: "auto" }}
        />
        <p>{t("scan_qr_code")}</p>
      </div>
      <div className={style.code}>
        <p>{t("or_enter_code_manually")}</p>
        <div className={style.copyCode}>
          <IoCopyOutline
            onClick={() => navigator.clipboard.writeText("test copy")}
          />
          <p>{sessionStorage.getItem("secret")}</p>
        </div>
      </div>
      <div className={style.actionBtns}>
        <CustomBtn type="primary" rootClassName={style.cancelBtn}>
          <CustomLink to="/auth/sign-up">{t("cancel")}</CustomLink>
        </CustomBtn>
        <CustomBtn type="primary">
          <CustomLink to="/auth/google-sign-up-verify">
            {t("continue")}
          </CustomLink>
        </CustomBtn>
      </div>
    </div>
  );
}
