/** @format */

import { useTranslation } from "react-i18next";
import Box from "../../../components/Common/Box";
import AddTicketForm from "../../../components/Support/AddTicket";

export default function AddTicket() {
  const { t } = useTranslation();
  return (
    <Box title={t("addTicket")}>
      <AddTicketForm />
    </Box>
  );
}
