/** @format */

import { Form, Input, Select } from "antd";

import clsx from "clsx";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PhoneInput } from "react-international-phone";
import { useNavigate } from "react-router-dom";
import { useSignUp } from "../../../../hooks/auth";
import {
  useGetAllCities,
  useGetAllCountries,
  useGetAllRegions,
} from "../../../../hooks/lookups";
import Validation from "../../../../validation/antDesign";
import CustomBtn from "../../../Common/CustomBtn/CustomBtn";
import CustomLink from "../../../Common/CustomLink/CustomLink";
import styles from "../Forms.module.scss";

function SignUpForm({ formHeading }: { formHeading: string }) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [inputPhoneHasError, setInputPhoneHasError] = useState(false);

  const [address, setAddress] = useState({
    country_id: form.getFieldValue("country_id"),
    region_id: form.getFieldValue("region_id"),
    city_id: form.getFieldValue("city_id"),
  });
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const {
    data: countries,
    isLoading: countriesLoading,
    isSuccess: countriesIsSuccess,
  } = useGetAllCountries();

  const {
    data: regions,
    isLoading: regionsLoading,
    isSuccess: regionsIsSuccess,
  } = useGetAllRegions();
  const {
    data: cities,
    isLoading: citiesLoading,
    isSuccess: citiesIsSuccess,
  } = useGetAllCities();

  const countriesOptions =
    countries?.map((country) => ({
      label: country.name,
      value: country.id,
    })) || [];

  const regionsOptions =
    regions
      ?.filter((region) =>
        address.country_id ? region.country_id === +address.country_id : true
      )
      .map((region) => ({
        label: region.name,
        value: region.id,
      })) || [];

  const citiesOptions =
    cities
      ?.filter((city) =>
        address.region_id ? city.region_id === +address.region_id : true
      )
      .map((country) => ({
        label: country.name,
        value: country.id,
      })) || [];

  const { mutate, isLoading } = useSignUp(navigate);
  return (
    <Form
      onFinish={(values) => {
        console.log({ values });
        sessionStorage.setItem("registerData", JSON.stringify(values));
        mutate(values);
      }}
      form={form}
      rootClassName={styles.form}
    >
      <h2 className={styles.formHeading}>{formHeading}</h2>

      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label={t("company_name")}
        name="name"
        rules={[
          {
            required: true,
            message: `  ${t("company_name")}  ${t("required")}`,
          },
        ]}
      >
        <Input placeholder={`${t("entered")} ${t("company_name")}  `} />
      </Form.Item>
      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label={`   ${t("company_owner")}/  ${t("responsible_manger")}`}
        name="owner_name"
        rules={[
          {
            required: true,
            message: `${t("company_owner")}/  ${t("responsible_manger")} ${t(
              "required"
            )}`,
          },
        ]}
      >
        <Input
          placeholder={`${t("entered")} ${t("company_owner")}/  ${t(
            "responsible_manger"
          )}  `}
        />
      </Form.Item>
      <Form.Item
        rootClassName={clsx(
          styles.inputGroup,
          styles.phoneInput,
          inputPhoneHasError && styles.error
        )}
        layout="vertical"
        label={t("phone")}
        name="phone"
      >
        <PhoneInput
          name="phone"
          value={form.getFieldValue("phone")}
          defaultCountry="sa"
          onChange={(value, { country }) => {
            // setCountryCode("+" + country.dialCode);
            form.setFieldValue("phone", value);
          }}
        />
      </Form.Item>

      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label={t("country")}
        name="country_id"
        rules={[
          { required: true, message: `   ${t("country")} ${t("required")}` },
        ]}
      >
        <Select
          style={{ width: "100%" }}
          placeholder={`${t("select")} ${t("country")} `}
          options={countriesOptions}
          loading={countriesLoading}
          onChange={(val) => {
            setAddress({ city_id: "", region_id: "", country_id: val });
            form.setFieldValue("country_id", val);
          }}
        />
      </Form.Item>
      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label={t("region")}
        name="region_id"
        rules={[
          { required: true, message: `   ${t("region")} ${t("required")}` },
        ]}
      >
        <Select
          disabled={!address.country_id}
          style={{ width: "100%" }}
          placeholder={`${t("select")} ${t("region")} `}
          options={regionsOptions}
          loading={regionsLoading}
          onChange={(val) => {
            setAddress({ ...address, city_id: "", region_id: val });
            form.setFieldValue("region_id", val);
          }}
        />
      </Form.Item>
      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label={t("city")}
        name="city_id"
        rules={[
          { required: true, message: `   ${t("city")}  ${t("required")}` },
        ]}
      >
        <Select
          disabled={!address.region_id}
          style={{ width: "100%" }}
          placeholder={`${t("select")} ${t("city")} `}
          options={citiesOptions}
          loading={citiesLoading}
          onChange={(val) => {
            setAddress({ ...address, city_id: val });
            form.setFieldValue("city_id", val);
          }}
        />
      </Form.Item>
      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label={t("email")}
        name="email"
        rules={[{ required: true, message: ` ${t("email")} ${t("required")}` }]}
      >
        <Input type="email" placeholder="example@mail.com" />
      </Form.Item>

      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label={t("password")}
        name="password"
        rules={Validation.password(t)}
      >
        <Input.Password
          autoComplete="off"
          placeholder={` ${t("write")}  ${t("password")}`}
        />
      </Form.Item>

      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label={t("confirm_password")}
        name="password_confirmation"
        rules={Validation.confirmPassword(t)}
      >
        <Input.Password placeholder={` ${t("write")}  ${t("password")}`} />
      </Form.Item>

      <div className={clsx(styles.footer, styles.note)}>
        <p>
          {t("terms_conditions_desc")}
          <CustomLink to={`/auth/sign-up`}> {t("terms_conditions")}</CustomLink>
        </p>
      </div>
      <CustomBtn
        disabled={isLoading}
        loading={isLoading}
        rootClassName={styles.submit}
        type="primary"
        htmlType="submit"
      >
        {t("continue")}
      </CustomBtn>
      <div className={styles.footer}>
        <p>{t("already_have_account")}</p>
        <CustomLink to={`/auth/sign-in`}> {t("sign_in")}</CustomLink>
      </div>
    </Form>
  );
}
export default SignUpForm;
