import clsx from "clsx";
import { useTranslation } from "react-i18next";
import CustomBtn from "../Common/CustomBtn/CustomBtn";
import styles from "./FormActionButtons.module.scss";

type TProps = {
  onClickCancel: () => void;
  formID: string;
  loading?: boolean;
  saveBtnText?: string;
  cancelBtnText?: string;
  className?: string;
};
function FormActionButtons({
  onClickCancel,
  loading = false,
  saveBtnText,
  cancelBtnText,
  className,
  formID,
}: TProps) {
  const { t } = useTranslation();
  return (
    <div className={clsx(styles.formActionButtons, className)}>
      <CustomBtn
        form={formID}
        disabled={loading}
        loading={loading}
        htmlType="submit"
      >
        {saveBtnText || t("save")}
      </CustomBtn>
      <CustomBtn
        disabled={loading}
        size="middle"
        className={styles.cancelBtn}
        onClick={onClickCancel}
      >
        {cancelBtnText || t("cancel")}
      </CustomBtn>
    </div>
  );
}

export default FormActionButtons;
