import { useTranslation } from "react-i18next";
import ListOrders from "../../components/Orders/ListOrders/ListOrders";

function Orders() {
  const { t } = useTranslation();

  document.title = t("my_orders");
  return <ListOrders />;
}

export default Orders;
