import { TableProps } from "antd";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import CustomTable from "../../Common/CustomTable/CustomTable";

import { TOrderDetails, TOrderDetailsProduct } from "../../../types/order";
import styles from "./ProductTable.module.scss";

type TProps = {
  data?: TOrderDetails;
};
function ProductTable({ data }: TProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const currencyCode = data?.order_details?.currency?.code;

  const columns: TableProps<TOrderDetailsProduct>["columns"] = useMemo(
    () => [
      {
        title: "#",
        dataIndex: "id",
        key: "id",
        render: (val) => <b>{val}</b>,
      },
      {
        title: t("product_description"),
        dataIndex: "product_details",
        key: "product_details",
        render: (_, record) => <b>{record?.product?.name}</b>,
      },
      {
        title: t("qty"),
        dataIndex: "quantity",
        key: "quantity",
      },
      {
        title: t("unit_price"),
        dataIndex: "unit_price",
        key: "unit_price",
        render: (val) => <p>{val.toFixed(2)}</p>,
      },
      {
        title: t("tax_type"),
        dataIndex: "value_added_tax",
        key: "value_added_tax",
        render: (_, record) => (
          <b>
            {!record?.product?.value_added_tax
              ? t("include_tax")
              : record?.product?.value_added_tax?.country?.name}
          </b>
        ),
      },
      {
        title: t("tax_rate"),
        dataIndex: "calc_tax_rate",
        key: "calc_tax_rate",
        render: (_, record) => <b>{record?.product?.calc_tax_rate}</b>,
      },
      {
        title: t("tax_value"),
        dataIndex: "calc_tax_amount",
        key: "calc_tax_amount",
        render: (_, record) => <b>{record?.product?.calc_tax_amount}</b>,
      },
      {
        title: t("total_value"),
        dataIndex: "total",
        key: "total",
        render: (val) => <b>{val}</b>,
      },
    ],
    [language]
  );

  return (
    <div className={styles.productTable}>
      <CustomTable
        columns={columns as any}
        dataSource={data?.order_details.order_products}
        className={styles.table}
        configStyle={{
          fontWeightStrong: 500,
          headerColor: "#878A99",
          padding: 10,
        }}
      />
      <hr className={styles.dashedLine} />

      <div className={styles.orderDetailsFooter}>
        <ul className={styles.invoice}>
          <li>
            <p> {t("total_without_tax")} : </p>
            <p>{data?.order_details?.sub_total?.toFixed(2)} </p>
          </li>
          <li>
            <p> {t("total_tax_value")}: </p>
            <p>{data?.order_details?.tax?.toFixed(2)}</p>
          </li>
          <li className={styles.dashedLine} />
          <li>
            <p>
              <b>{t("total_with_tax")}</b>
            </p>
            <p>
              <b>{data?.order_details?.total?.toFixed(2)}</b> {currencyCode}
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ProductTable;
