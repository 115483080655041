/** @format */

import { Modal, Table, TableProps } from "antd";
import clsx from "clsx";
import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BsFillEyeFill } from "react-icons/bs";
import useAuthorization from "../../hooks/useAuthorization";
import { useGitTicket } from "../../hooks/useTicket";
import { TTicket } from "../../types/attachment";

import Box from "../Common/Box";
import CustomLink from "../Common/CustomLink/CustomLink";
import { errorMsgs } from "./AddTicket";
import style from "./style.module.scss";
const mapStatus: Record<string, Record<string, string>> = {
  ar: {
    pending: "جاري العمل",
    new: "جديد",
    closed: "مغلق",
    complete: "اكتمل",
  },
  en: {
    pending: "Pending",
    new: "New",
    closed: "Closed",
    complete: "Complete",
  },
};
const mapStatusToStyle: Record<string, string> = {
  pending: style.pending,
  complete: style.complete,
  new: style.new,
  closed: style.closed,
};
export default function SupportPage() {
  const [showTicket, toggleTicketReplay] = useState<boolean>(false);
  const [selectedTicket, setTicket] = useState<TTicket | undefined>();
  const { data, isLoading } = useGitTicket();
  const openReplay = (record?: TTicket) => {
    setTicket(record);
    toggleTicketReplay(!showTicket);
  };
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const columns: TableProps<TTicket>["columns"] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      render: (text) => <b>{text}</b>,
    },
    {
      title: () => <p className={style.productHeader}>{t("date_added")}</p>,
      dataIndex: "created_at",
      key: "created_at",
      render: (text: string) => (
        <p className={style.createdAt}>
          {dayjs(text).format("DD-MMMM-YYYY HH:mm a")}
        </p>
      ),
    },
    {
      title: t("ticket_title"),
      dataIndex: "title",
      key: "title",
    },

    {
      title: t("order_no"),
      key: "orderNumber",
      dataIndex: "orderNumber",
      render: (text: number) => <b className={style.ticketNum}>#{text}</b>,
    },
    {
      title: t("status"),
      key: "status",
      dataIndex: "status",
      render: (text: string) => (
        <p className={clsx(style.status, mapStatusToStyle[text])}>
          {mapStatus[language][text]}
        </p>
      ),
    },
    {
      title: t("comment"),
      key: "replies",
      dataIndex: "replies",
      render: (_, record) =>
        !record.replies.length ? (
          <BsFillEyeFill
            onClick={() => openReplay(record)}
            className={style.eye}
          />
        ) : null,
    },
  ];
  const { hasPermission } = useAuthorization();
  return (
    <Box title={t("support")}>
      <>
        {hasPermission("AddTicket") ? (
          <CustomLink to={"/support/add-ticket"}>
            <button className={style.addTicket}> {t("addTicket")} +</button>
          </CustomLink>
        ) : null}

        <div className={style.ticketTable}>
          <Table<TTicket>
            className={style.balanceTable}
            columns={columns}
            pagination={false}
            dataSource={data}
            loading={isLoading}
          />
          <Modal
            title={t("ticket_details")}
            open={showTicket}
            width={700}
            cancelText={t("close")}
            okButtonProps={{ style: { display: "none" } }}
            cancelButtonProps={{
              style: { border: "none", backgroundColor: "#f3f6f9" },
            }}
            onCancel={() => toggleTicketReplay(false)}>
            <div className={style.ticket}>
              <div className={style.ticketBody}>
                <p className={style.title}>{t("complaint_title")}</p>
                <p className={style.value}>{selectedTicket?.title}</p>
                <p className={style.title}>{t("details")} </p>
                <p className={style.value}>{selectedTicket?.details}</p>
                <p className={style.title}>{t("error_msg")}</p>
                <p className={style.value}>
                  {t(
                    errorMsgs.find(
                      (msg) => msg.value === selectedTicket?.error_message
                    )?.label || ""
                  )}
                </p>
              </div>
              <img src={selectedTicket?.attachment.file_url} alt='الشكوى' />
            </div>
          </Modal>
        </div>
      </>
    </Box>
  );
}
