import { Form, Input } from "antd";
import FormItem from "antd/es/form/FormItem";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import ArabicFlag from "../../../assets/icons/ae-flag.svg";
import EnglishFlag from "../../../assets/icons/us-flag.svg";
import {
  useAddRole,
  useGetRoleDetails,
  useUpdateRole,
} from "../../../hooks/roles";
import { TRoleBody } from "../../../types/roles";
import CustomSwitcher from "../../Common/CustomSwitcher/CustomSwitcher";
import SectionCard from "../../Common/SectionCard/SectionCard";
import CustomSkelton from "../../CustomSkelton/CustomSkelaton";
import FormActionButtons from "../../FormActionButtons/FormActionButtons";
import PermissionsCategory from "../PermissionsCategories/PermissionsCategories";
import styles from "./RoleForm.module.scss";
function RoleForm() {
  const navigate = useNavigate();
  const { mutate: addRole, isLoading: addRoleLoader } = useAddRole(navigate);
  const { mutate: updateRole, isLoading: updateRoleLoader } =
    useUpdateRole(navigate);
  const [form] = Form.useForm();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { id } = useParams();
  const isEdit = !!id;
  const { data: roleDetails, isLoading } = useGetRoleDetails(Number(id));
  const formID = "roleForm";

  const handleSubmitForm = (values: any) => {
    const body: TRoleBody = {
      name: values.name_en,
      display_name: [values.name_en, values.name_en, values.name_ar],
      permissions: values.permissions,
      status: values.status ? "active" : "inactive",
    };
    if (id) {
      updateRole({ id: +id, body });
    } else {
      addRole(body);
    }
  };
  const pageName = isEdit
    ? `${t("edit")}  ${t("employee_role")}`
    : `${t("add")}  ${t("employee_role")}`;
  document.title = pageName;
  if (isLoading) {
    return <CustomSkelton />;
  }

  return (
    <Form
      className={styles.roleForm}
      form={form}
      id={formID}
      onFinish={(values) => handleSubmitForm(values)}
      initialValues={{
        name_ar: roleDetails?.translations?.[1]?.display_name,
        name_en: roleDetails?.translations?.[0]?.display_name,
        status: roleDetails?.status === "active",
        permissions: roleDetails?.permissions?.map(
          (permission) => permission.name
        ),
      }}
    >
      <div className={styles.header}>
        <h3>{pageName}</h3>
        <FormActionButtons
          loading={addRoleLoader || updateRoleLoader}
          formID={formID}
          className={styles.formActions}
          onClickCancel={() =>
            navigate(`/${language}/settings/employees#roles`)
          }
        />
      </div>
      <SectionCard
        title={t("role_details")}
        rootClassName={styles.sectionCardForm}
      >
        <div className={styles.form}>
          <Form.Item
            rootClassName={styles.inputGroup}
            layout="vertical"
            label={t("role_name_ar")}
            name="name_ar"
            rules={[
              {
                required: true,
                message: `${t("role_name_ar")} ${t("required")}`,
              },
            ]}
          >
            <Input
              style={{ padding: 0 }}
              placeholder={`${t("write")} ${t("role_name_ar")}`}
              prefix={
                <div className={styles.flagBox}>
                  <img alt="language" src={ArabicFlag} />
                </div>
              }
            />
          </Form.Item>
          <Form.Item
            rootClassName={styles.inputGroup}
            layout="vertical"
            label={t("role_name_en")}
            name="name_en"
            rules={[
              {
                required: true,
                message: `${t("role_name_en")} ${t("required")}`,
              },
            ]}
          >
            <Input
              style={{ padding: 0 }}
              placeholder={`${t("entered")} ${t("role_name_en")}`}
              prefix={
                <div className={styles.flagBox}>
                  <img alt="language" src={EnglishFlag} />
                </div>
              }
            />
          </Form.Item>

          <Form.Item
            rootClassName={clsx(styles.inputGroup, styles.statusInput)}
            layout="vertical"
            name="status"
          >
            <CustomSwitcher
              defaultChecked={roleDetails?.status === "active"}
              label={`${t("status")}:`}
            />
          </Form.Item>
        </div>
        <FormItem
          name={"permissions"}
          rules={[{ required: true, message: t("permissions_required") }]}
        />
        <PermissionsCategory
          defaultPermissions={
            roleDetails?.permissions?.map((permission) => permission.name) || []
          }
          getPermissions={(permissions) => {
            form.setFieldValue("permissions", permissions);
          }}
        />
      </SectionCard>
    </Form>
  );
}

export default RoleForm;
