/** @format */
"use client";
import { Input, Tabs } from "antd";
import { useCallback, useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import Box from "../Common/Box/index";

import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useGetProducts } from "../../hooks/products";
import PaginationCom from "../Common/PaginationComp";
import Prices from "./Prices";
import style from "./style.module.scss";

const { TabPane } = Tabs;

export type TProductFilters = {
  page?: string;
  searchValue?: string;
  pageSize?: string;
};
export default function Products() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [pageSize, setPageSize] = useState("20");
  const [searchValue, setSearch] = useState("");
  const [filters, setFilters] = useState<TProductFilters>({
    page: searchParams.get("page") || "1",
  });
  const { t } = useTranslation();
  const { data, isLoading } = useGetProducts({
    ...filters,
    pageSize,
    searchValue,
  });

  // const { data: searchResult, refetch } = useSearchProducts(searchValue);
  const { page } = filters;
  const gatPage = (page: number) => {
    setSearchParams({ ...filters, page: page.toString() });
    setFilters({ ...filters, page: page.toString() });
  };
  useEffect(() => {
    return () => {
      setSearch("");
    };
  }, []);
  const debounce = <F extends (...args: any[]) => void>(
    func: F,
    delay: number
  ) => {
    let timeoutId: NodeJS.Timeout;

    return function (this: any, ...args: Parameters<F>) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };
  const handleInput = useCallback(
    debounce((value: string) => {
      // refetch();
    }, 700),
    []
  );
  const handleChange = (value: string) => {
    setSearch(value);
    setFilters({ page: "1" });
    // handleInput(value);
  };

  return (
    <div>
      <Box title={t("product_list")}>
        <div className={style.productsCont}>
          <div className={style.filterCont}>
            {/* <Button size='large'>
              تصفية <IoMdOptions />
            </Button> */}
            <Input
              onChange={(event) => handleChange(event.target.value)}
              prefix={<CiSearch />}
              placeholder={t("search_for_prod")}
            />
          </div>
          <div className={style.tabs}>
            {/* <Tabs
              tabBarStyle={{ paddingInlineStart: "12px", margin: 0 }}
              className={style.tabCont}
              defaultActiveKey='1'>
              <TabPane
                tab={<span style={{ padding: " 12px" }}>المخزون</span>}
                key='1'>
                <Stock data={searchResult ? searchResult : data || []} />
              </TabPane>
              <TabPane
                tab={<span style={{ padding: " 12px" }}>الأسعار</span>}
                key='2'>
                <Prices data={searchResult ? searchResult : data || []} />
              </TabPane>
            </Tabs> */}
            <Prices
              data={data?.data || []}
              isLoading={isLoading}
              pageSize={pageSize}
            />

            <div className={clsx(style.paginationCont, "paginationCont")}>
              <PaginationCom
                setPage={gatPage}
                currentPage={Number(page)}
                total={data?.total || 1}
                setPageSize={setPageSize}
                perPage={pageSize}
              />
              <p>
                {t("show")} {(Number(page) - 1) * 10 + 1} {t("to")}{" "}
                {Number(page) * 10} {t("from")} {data?.total} {t("brand")}
              </p>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
}
