import clsx from "clsx";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import CustomBtn from "../../Common/CustomBtn/CustomBtn";
import styles from "./ReportsTab.module.scss";

type TProps = {
  active: string;
  setActive: Dispatch<SetStateAction<string>>;
};
function ReportsTab({ active, setActive }: TProps) {
  const { t } = useTranslation();
  const items = [
    {
      key: "products",
      label: t("products"),
    },
    {
      key: "orders",
      label: t("orders"),
    },
    {
      key: "ordersDetails",
      label: t("order_details"),
    },
  ];
  return (
    <div className={styles.reportsTab}>
      {items.map((item) => (
        <CustomBtn
          onClick={() => setActive(item.key)}
          key={item.key}
          className={clsx(styles.btn, active === item.key && styles.btnActive)}
        >
          {item.label}
        </CustomBtn>
      ))}
    </div>
  );
}

export default ReportsTab;
