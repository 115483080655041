/** @format */

import { AxiosResponse } from "axios";
import { TProductFilters } from "../components/Products";
import { TPaginationResponse, TResponse } from "../types/api";
import { THome, TProduct } from "../types/products";
import api from "./api";

export const productService = {
  getProducts: async (
    filters?: TProductFilters
  ): Promise<AxiosResponse<TPaginationResponse<TProduct[]>>> =>
    api.get(
      `/products?name=${filters?.searchValue || ""}&page=${
        filters?.page || ""
      }&per_page=${filters?.pageSize || ""}`
    ),

  searchProducts: async (
    search: string
  ): Promise<AxiosResponse<TResponse<TProduct[]>>> =>
    api.get("/search-products?name=" + search),
  getHome: async (): Promise<AxiosResponse<TResponse<THome>>> =>
    api.get("/dashboard"),
};
