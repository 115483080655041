/** @format */

"use client";
import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useForgetPassword } from "../../../../hooks/auth";
import CustomBtn from "../../../Common/CustomBtn/CustomBtn";
import styles from "../Forms.module.scss";

function ForgetPasswordForm({ formHeading }: { formHeading: string }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { mutate, isLoading } = useForgetPassword(navigate);
  return (
    <Form onFinish={(values) => mutate(values)} rootClassName={styles.form}>
      <h2 className={styles.formHeading}>{formHeading}</h2>

      <p className={styles.forgetPasswordNote}>{t("enter_your_email")} </p>
      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label={t("email")}
        name="email"
        rules={[{ required: true, message: ` ${t("email")} ${t("required")}` }]}
      >
        <Input type="email" placeholder={`${t("entered")} ${t("email")}`} />
      </Form.Item>

      <CustomBtn
        disabled={isLoading}
        loading={isLoading}
        rootClassName={styles.submit}
        type="primary"
        htmlType="submit"
      >
        {t("resend_reset_link")}{" "}
      </CustomBtn>
    </Form>
  );
}
export default ForgetPasswordForm;
