/** @format */

import { useTranslation } from "react-i18next";
import useAuthorization from "../../../hooks/useAuthorization";
import { TUser } from "../../../types/auth";
import formateDate from "../../../utils/formateDate.";
import CustomLink from "../../Common/CustomLink/CustomLink";
import styles from "./SellerDetails.module.scss";

type TProps = {
  data?: TUser;
};
function SellerDetails({ data }: TProps) {
  const { hasPermission } = useAuthorization();
  const { t } = useTranslation();

  const sellerDetails = (data?: TUser) => [
    {
      label: t("company_name"),
      value: data?.name,
    },
    {
      label: t("responsible_manger"),
      value: data?.owner_name,
    },
    {
      label: t("phone"),
      value: data?.phone,
    },
    {
      label: t("email"),
      value: data?.email,
    },
    {
      label: t("commercial_register_number"),
      value: data?.commercial_register_number,
    },
    {
      label: t("tax_card_number"),
      value: data?.tax_card_number,
    },
    {
      label: t("country"),
      value: data?.country?.name,
    },
    {
      label: t("city"),
      value: data?.city?.name,
    },
    {
      label: t("address"),
      value: data?.address,
    },
    {
      label: t("join_date"),
      value: formateDate(data?.created_at || "", "DD MMMM YYYY"),
    },
  ];

  return (
    <div className={styles.sellerDetails}>
      <div className={styles.header}>
        <h5 className={styles.title}>{t("seller_details")}</h5>
        {hasPermission("SettingUpdateProfile") && (
          <div className={styles.edit}>
            <CustomLink to={"/settings/profile/edit"}>
              {t("edit_data")}
              {/* <GoPencil className={styles.pencil} /> */}
            </CustomLink>
          </div>
        )}
      </div>
      <table className={styles.content}>
        <tbody>
          {sellerDetails(data).map((item) => (
            <tr key={item.label} className={styles.tableRow}>
              <th>{item.label} :</th>
              <td>{item.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default SellerDetails;
