/** @format */

import React from "react";
import { useTranslation } from "react-i18next";
import Box from "../Common/Box";
import Cart from "./Cart";
import style from "./style.module.scss";

export default function StorePage({
  title,
  children,
}: {
  title?: string;
  children: React.ReactElement;
}) {
  const { t } = useTranslation();
  return (
    <div className={style.storeCont}>
      <Box title={title || t("store")}>{children}</Box>
      <Box title={t("cart")}>
        <Cart />
      </Box>
    </div>
  );
}
