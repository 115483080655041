/** @format */

import clsx from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import StorePage from "..";
import { useNewProducts } from "../../../hooks/store";
import { TGetProducts } from "../../../types/products";
import PaginationCom from "../../Common/PaginationComp";
import ProductList from "../ProductList";

import style from "./style.module.scss";
export type TFilters = { search: string; page: string };
export default function ProductComp() {
  const [pageSize, setPageSize] = useState("10");
  const [productsData, setData] = useState<TGetProducts | undefined>(undefined);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<TFilters>({
    page: searchParams.get("page") || "1",
    search: searchParams.get("search") || "",
  });
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const navigate = useNavigate();
  let { categoryId, brandId } = useParams();
  const { data, refetch } = useNewProducts(
    categoryId || "",
    brandId || "",
    searchParams.get("page") || "",
    searchParams.get("search") || "",
    pageSize
  );
  useEffect(() => {
    if (data) {
      setData(data);
    }
  }, [data]);
  const saveFilters = (stateName: string, value: string) => {
    setSearchParams((prevStat) => ({
      ...prevStat,
      [stateName]: value.toString(),
    }));
    // refetch();
    setFilters({ ...filters, [stateName]: value });
  };

  return (
    <StorePage title={t("store")}>
      {productsData ? (
        <>
          <div className={style.navigationHeader}>
            {productsData.brand ? (
              <div className={style.brand}>
                <img src={productsData.brand.images.logo} alt='brandLogo' />
                <h3>{productsData.brand.name}</h3>
              </div>
            ) : null}

            <div className={style.category}>
              <img src={productsData.category.image} alt='brandLogo' />
              <h3>{productsData.category.name}</h3>
            </div>
            <button onClick={() => navigate(`/${language}/store`)}>
              {" "}
              <MdOutlineKeyboardDoubleArrowLeft />
              {t("back")}
            </button>
          </div>
          <ProductList
            perPage={Number(pageSize)}
            data={productsData?.products.data || []}
            total={productsData.products.total}
            saveFilters={saveFilters}
            filters={filters}
            categoryId={productsData.category.id}
            isTopup={productsData.category.is_topup}
          />

          <div className={clsx(style.paginationCont, "paginationCont")}>
            <PaginationCom
              setPageSize={setPageSize}
              setPage={(value: number) => saveFilters("page", value.toString())}
              currentPage={Number(filters.page)}
              total={productsData.products.total}
              perPage={pageSize}
            />
            <p>
              {t("show")} {(Number(filters.page) - 1) * Number(pageSize) + 1}{" "}
              {t("to")}{" "}
              {Number(filters.page) * Number(pageSize) >
              productsData.products.total
                ? productsData.products.total
                : Number(filters.page) * Number(pageSize)}{" "}
              {t("from")} {productsData.products.total} {t("card")}
            </p>
          </div>
        </>
      ) : (
        <div />
      )}
    </StorePage>
  );
}
