import { useTranslation } from "react-i18next";
import InvoiceDetail from "../../components/Invoice";

function Invoice() {
  const { t } = useTranslation();
  document.title = t("invoice");
  return <InvoiceDetail />;
}

export default Invoice;
