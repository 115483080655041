type TMethod = (key: string) => string;
const Validation = {
  password: (t: TMethod) => [
    {
      required: true,
      message: `${t("password")} ${t("required")}`,
    },
    { min: 8, message: t("password_validation") },
  ],
  confirmPassword: (t: TMethod) => [
    {
      required: true,
      message: ` ${t("confirm_password")} ${t("required")}`,
    },
    ({ getFieldValue }: { getFieldValue: TMethod }) => ({
      validator(_: any, value: string) {
        if (!value || getFieldValue("password") === value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(t("password_does_not_match")));
      },
    }),
  ],
};

export default Validation;
