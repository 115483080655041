/** @format */

import { Checkbox, Form, Input } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSignIn } from "../../../../hooks/auth";
import Validation from "../../../../validation/antDesign";
import CustomBtn from "../../../Common/CustomBtn/CustomBtn";
import CustomLink from "../../../Common/CustomLink/CustomLink";
import styles from "../Forms.module.scss";

function SignInForm({ formHeading }: { formHeading: string }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { mutate, isLoading } = useSignIn(navigate);
  const [rememberMe, setRememberMe] = useState(false);
  return (
    <Form
      onFinish={(values) => mutate({ ...values, remember_me: rememberMe })}
      rootClassName={styles.form}
    >
      <h2 className={styles.formHeading}>{formHeading}</h2>

      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label={t("email")}
        name="email"
        rules={[{ required: true, message: ` ${t("email")} ${t("required")}` }]}
      >
        <Input type="email" placeholder={`${t("entered")} ${t("email")}`} />
      </Form.Item>

      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label={
          <div className={styles.passwordLabel}>
            <p> {t("password")}</p>
            <CustomLink to={"/auth/forget-password"}>
              {t("forget_password")}{" "}
            </CustomLink>
          </div>
        }
        name="password"
        rules={Validation.password(t)}
      >
        <Input.Password placeholder={`${t("entered")}  ${t("password")}`} />
      </Form.Item>
      <Form.Item
        rootClassName={styles.rememberMe}
        name="remember_me"
        valuePropName="checked"
      >
        <Checkbox
          checked={rememberMe}
          onChange={() => setRememberMe((prev) => !prev)}
        >
          {t("remember_me")}
        </Checkbox>
      </Form.Item>
      <CustomBtn
        disabled={isLoading}
        loading={isLoading}
        rootClassName={styles.submit}
        type="primary"
        htmlType="submit"
      >
        {t("login")}
      </CustomBtn>
      <div className={styles.footer}>
        <p>{t("dont_have_account")}</p>
        <CustomLink to={"/auth/sign-up"}> {t("register_now")}</CustomLink>
      </div>
    </Form>
  );
}
export default SignInForm;
