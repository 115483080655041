export const sellerStatus = {
  approved: {
    label: "verified",
    color: "green",
  },
  pending: {
    label: "pending",
    color: "yellow",
  },
  rejected: {
    label: "unverified",
    color: "red",
  },
};
