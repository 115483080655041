export const orderStatus = {
  completed: {
    color: "green",
    label: "payment_complete",
  },
  waiting: {
    color: "yellow",
    label: "pending",
  },
  rejected: {
    color: "red",
    label: "rejected",
  },
  in_progress: {
    color: "blue",
    label: "in_progress",
  },
};
