/** @format */

import { useTranslation } from "react-i18next";
import ForgetPassword from "../../../assets/lottie/lottie-forget-password.json";
import AuthLayout from "../../../components/auth/AuthLayout/AuthLayout";
import ForgetPasswordForm from "../../../components/auth/Forms/ForgetPasswordForm/ForgetPasswordForm";
function Page() {
  const { t } = useTranslation();
  document.title = t("forget_password");
  return (
    <AuthLayout lottie={ForgetPassword}>
      <ForgetPasswordForm formHeading={t("reset_password")} />
    </AuthLayout>
  );
}
export default Page;
