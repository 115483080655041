import { useTranslation } from "react-i18next";
import { IoPrintSharp } from "react-icons/io5";
import { LuDownload } from "react-icons/lu";
import { RiArrowGoBackFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import CustomBtn from "../../Common/CustomBtn/CustomBtn";
import styles from "./InvoiceFooter.module.scss";

const BtnStyles = {
  height: "4rem",
};

type TProps = {
  toPDF: () => void;
};
function InvoiceFooter({ toPDF }: TProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className={styles.invoiceFooter}>
      <div className={styles.notes}>
        <p>This document printed from system, no need to stamp or signature.</p>
        <p>هذه الوثيقة مطبوعة من النظام ولا تحتاج الي ختم أو توقيع</p>
        <p>30/04/2024 15:57:35</p>
      </div>
      <div className={styles.actionBtns}>
        <CustomBtn
          style={BtnStyles}
          icon={<IoPrintSharp />}
          onClick={() => window.print()}
        >
          {t("print")}
        </CustomBtn>
        <CustomBtn
          style={BtnStyles}
          className={styles.downloadBtn}
          icon={<LuDownload />}
          onClick={toPDF}
        >
          {t("download")}
        </CustomBtn>
        <CustomBtn
          style={BtnStyles}
          className={styles.backBtn}
          icon={<RiArrowGoBackFill />}
          onClick={() => navigate(-1)}
        >
          {t("back")}{" "}
        </CustomBtn>
      </div>
    </div>
  );
}

export default InvoiceFooter;
