/** @format */

import { useTranslation } from "react-i18next";
import ForgetPassword from "../../../assets/lottie/lottie-forget-password.json";
import AuthLayout from "../../../components/auth/AuthLayout/AuthLayout";
import ResetPasswordForm from "../../../components/auth/Forms/ResetPasswordForm/ResetPasswordForm";
function ResetPassword() {
  const { t } = useTranslation();
  document.title = t("reset_password");
  return (
    <AuthLayout lottie={ForgetPassword}>
      <ResetPasswordForm formHeading={t("reset_password")} />
    </AuthLayout>
  );
}
export default ResetPassword;
