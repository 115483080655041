import { useTranslation } from "react-i18next";
import { TOrderDetailsProduct } from "../../../types/order";
import styles from "./ProductDetails.module.scss";

type TProps = {
  product: TOrderDetailsProduct;
};
function ProductDetails({ product }: TProps) {
  const { t } = useTranslation();
  return (
    <div className={styles.productDetails}>
      <div className={styles.imgBox}>
        <img
          src={product?.product?.image}
          alt={product?.product?.product?.meta_description}
        />
      </div>
      <div className={styles.details}>
        <h4> {product?.product?.name} </h4>
        <p>
          {t("brand")}: <span>{product?.product?.brand?.name}</span>
        </p>
      </div>
    </div>
  );
}

export default ProductDetails;
