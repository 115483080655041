/** @format */

import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useGetProfile } from "../../../../hooks/profile";
import { TStoreProduct } from "../../../../types/products";
import style from "./style.module.scss";

export default function ProductDatails({
  product,
  isOpen,
  onClose,
}: {
  product: TStoreProduct;
  isOpen: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const { data: profile } = useGetProfile();
  return (
    <Modal width={"70%"} open={isOpen} footer={null} onCancel={onClose}>
      <div className={style.productDetails}>
        <div className={style.info}>
          <img src={product.image} alt={product.name} />
          <div className={style.nameAndPrice}>
            <h6>{product.name}</h6>
            <div className={style.priceAndCount}>
              <div className={style.priceAndTotalPrice}>
                <label> {t("unit_price")}:</label>
                <span className={style.price}>
                  <span>{Number(product?.wholesale_price).toFixed(2)}</span>{" "}
                  {profile?.currency?.code}
                </span>
              </div>

              <div className={style.priceAndTotalPrice}>
                <label>{t("selling_price")}:</label>
                <span className={style.price}>
                  <span>{Number(product?.price).toFixed(2)}</span>{" "}
                  {profile?.currency?.code}
                </span>
              </div>
            </div>
          </div>
        </div>
        {product.desc && product.desc.length ? (
          <>
            <h3>{t("product_details")}</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: product.desc || "",
              }}
            />
          </>
        ) : null}
        <button className={style.close} onClick={onClose}>
          {t("close")}
        </button>
      </div>
    </Modal>
  );
}
