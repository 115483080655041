import i18n from "../i18n";

export default async function changeLanguage(lang?: string) {
  if (!lang) return;

  await i18n.changeLanguage(lang);

  // Update the direction based on the selected language
  const direction = lang === "ar" ? "rtl" : "ltr";
  document.documentElement.dir = direction;

  document.documentElement.lang = lang;
}
