export const productStatus = {
  serial: {
    color: "blue",
    label: "serial",
  },
  topup: {
    color: "yellow",
    label: "topup",
  },
};
