/** @format */

import { TableProps, Tag } from "antd";
import { saveAs } from "file-saver";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEye, FaRegCopy } from "react-icons/fa";
import { LuDownload } from "react-icons/lu";
import { TbDownload } from "react-icons/tb";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { orderStatus } from "../../../constant/orderStatus";
import { useGetOrder } from "../../../hooks/order";
import {
  TOrderDetailsProduct,
  TOrderProductOption,
  TOrderProductSerials,
} from "../../../types/order";
import CustomTable from "../../Common/CustomTable/CustomTable";
import ProductDetails from "../ProductDetails/ProductDetails";
import TopupModal from "../TopupModal/TopupModal";
import styles from "./OrderDetails.module.scss";

function OrderDetails() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { orderId } = useParams();
  const [topupOptionsModal, setTopupOptionsModal] = useState<{
    isOpen: boolean;
    data: TOrderProductOption[];
  }>({
    isOpen: false,
    data: [],
  });
  const { data: orderDetails, isLoading } = useGetOrder(orderId as string);
  const currencyCode = orderDetails?.order_details?.currency?.code;

  const handleExport = (record: TOrderDetailsProduct) => {
    // Sample data to be added to the Excel sheet
    const data = record.order_product_serials.map(
      (item: TOrderProductSerials) => ({
        brand: record.product.brand.name,
        name: record.product.name,
        serialCard: item.serial,
        pinCard: item.scratching,
        validTill: item.expiring,
      })
    );

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert JSON data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate Excel file and save it
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Use file-saver to save the file
    const file = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(file, "product-serials.xlsx");
  };

  const columns: TableProps<TOrderDetailsProduct>["columns"] = useMemo(
    () => [
      {
        title: t("product_details"),
        dataIndex: "product",
        key: "product",
        render: (_, record) => <ProductDetails product={record} />,
        minWidth: 400,
      },
      {
        title: t("status"),
        dataIndex: "status",
        key: "status",
        render: (val) => (
          <Tag
            className={styles.tag}
            color={orderStatus[val as keyof typeof orderStatus]?.color}
          >
            {t(orderStatus[val as keyof typeof orderStatus]?.label)}{" "}
          </Tag>
        ),
      },
      {
        title: t("product_id"),
        dataIndex: "orderProductSerials",
        key: "orderProductSerials",

        render: (value, record) => {
          const isNotTopUp = record.type !== "topup";
          const canCopySerials = record?.order_product_serials?.length <= 5;
          if (record.status === "rejected") {
            return null;
          }
          return (
            <div
              className={styles.downloadFile}
              onClick={() => {
                if (isNotTopUp) {
                  const serialsToCopy = record?.order_product_serials
                    ?.map((item) => item.scratching)
                    .join("-");
                  if (!canCopySerials) {
                    handleExport(record);
                  } else {
                    navigator.clipboard.writeText(serialsToCopy);
                  }
                } else {
                  setTopupOptionsModal({
                    isOpen: true,
                    data: record.options,
                  });
                }
              }}
            >
              {isNotTopUp ? (
                <>
                  {canCopySerials ? (
                    <div>
                      {record?.order_product_serials.map((item) => (
                        <div className={styles.serials}>
                          <span>{item.scratching}</span>
                          <div
                            className={styles.iconCont}
                            onClick={() => {
                              navigator.clipboard.writeText(item.scratching);
                              toast.success("تم نسخ السريال بنجاح");
                            }}
                          >
                            <FaRegCopy className={styles.icon} />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className={styles.iconCont}>
                      <LuDownload className={styles.icon} />
                    </div>
                  )}
                </>
              ) : (
                <FaEye className={styles.icon} />
              )}
            </div>
          );
        },
      },
      {
        title: t("price"),
        dataIndex: "unit_price",
        key: "unit_price",
        render: (val, record) => <p>{`${val.toFixed(3)} ${currencyCode}`}</p>,
      },
      {
        title: t("qty"),
        dataIndex: "quantity",
        key: "quantity",
      },
      {
        title: t("total"),
        dataIndex: "total",
        key: "total",
        render: (val) => (
          <p>
            <b>{val.toFixed(3)}</b>
            &nbsp; {currencyCode}
          </p>
        ),
      },
    ],
    [language, currencyCode]
  );

  const hideShowInvoice = orderDetails?.order_details?.order_products?.some(
    (item) => item?.status === "rejected"
  );
  return (
    <div className={styles.orderDetails}>
      <TopupModal
        isOpen={topupOptionsModal.isOpen}
        data={topupOptionsModal.data}
        reset={() => setTopupOptionsModal({ isOpen: false, data: [] })}
      />
      <CustomTable
        heading={<h4>{t("order_details")}</h4>}
        buttonProps={
          !hideShowInvoice
            ? {
                btnValue: t("invoice"),
                href: `/${language}/invoice/${orderId}`,
                className: styles.invoiceBtn,
                icon: <TbDownload />,
              }
            : undefined
        }
        loading={isLoading}
        columns={columns as any}
        dataSource={orderDetails?.order_details?.order_products}
        configStyle={{
          fontWeightStrong: 500,
          headerColor: "#878A99",
          padding: 10,
        }}
      />
      <hr className={styles.dashedLine} />
      <div className={styles.orderDetailsFooter}>
        <ul className={styles.invoice}>
          <li>
            <p>{t("sub_total")} : </p>
            <p>
              {orderDetails?.order_details?.sub_total?.toFixed(3)}{" "}
              {currencyCode}
            </p>
          </li>
          <li>
            <p>{t("tax")}: </p>
            <p>{`${orderDetails?.order_details?.tax?.toFixed(
              3
            )} ${currencyCode}`}</p>
          </li>
          <li className={styles.dashedLine} />
          <li>
            <p>
              <b>{t("total")} : </b>
            </p>
            <p>
              <b>{orderDetails?.order_details?.total.toFixed(3)}</b>{" "}
              {currencyCode}
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default OrderDetails;
