import { FormInstance } from "antd";
import { UploadChangeParam, UploadFile } from "antd/es/upload";
import clsx from "clsx";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TAttachment } from "../../../types/attachment";
import CustomBtn from "../../Common/CustomBtn/CustomBtn";
import Upload from "../../Common/Upload/Upload";
import styles from "./UploadDocs.module.scss";
import "./_antd.scss";
type TProps = {
  form: FormInstance<any>;
  data?: TAttachment[];
};
type TTabs = "identity" | "commercial_register" | "tax_card" | "more";

function UploadDocs({ form, data }: TProps) {
  const [activeTab, setActiveTab] = useState<TTabs>("identity");
  const { t } = useTranslation();
  const [fileList, setFileList] = useState<any>({
    identity: data?.filter((item) => item.type === "identity"),
    commercial_register: data?.filter(
      (item) => item.type === "commercial_register"
    ),
    tax_card: data?.filter((item) => item.type === "tax_card"),
    more: data?.filter((item) => item.type === "more"),
  });

  const tabs: { key: TTabs; title: string }[] = [
    {
      key: "identity",
      title: t("identity"),
    },
    {
      key: "commercial_register",
      title: t("commercial_register"),
    },
    {
      key: "tax_card",
      title: t("tax_card"),
    },
    {
      key: "more",
      title: t("other"),
    },
  ];

  const onChangeFiles = (file: UploadChangeParam<UploadFile<any>>) => {
    form.setFieldValue(activeTab, file.file);
    setFileList((prev: any) => ({
      ...prev,
      [activeTab]: [file.file],
    }));
  };

  return (
    <div className={clsx(styles.uploadDocs, "uploadDocs")}>
      <h5 className={styles.title}>{t("select_attachment_type")}</h5>
      <div className={styles.tabs}>
        {tabs.map((item: { key: TTabs; title: string }) => (
          <CustomBtn
            key={item.key}
            onClick={() => setActiveTab(item.key)}
            size="large"
            className={clsx(
              styles.tab,
              activeTab === item.key && styles.tabActive
            )}
          >
            {item.title}
          </CustomBtn>
        ))}
      </div>
      <Upload
        accept=".png, .jpg, .jpeg, .pdf, .doc, .docx"
        name={activeTab}
        onChange={onChangeFiles}
        fileList={fileList[activeTab]}
      />
    </div>
  );
}

export default UploadDocs;
