import { useTranslation } from "react-i18next";
import ReportsContent from "../../components/Reports";

function Reports() {
  const { t } = useTranslation();
  document.title = t("reports");
  return <ReportsContent />;
}

export default Reports;
