/** @format */

import "dayjs/locale/ar";

// function formateDate(date?: string, format = "DD MMMM YYYY hh:mm A") {
//   dayjs.locale(i18n.language);
//   return dayjs(date).format(format);
// }

export const formateDate = (date = "", format = "DD MMMM YYYY hh:mm A") => {
  const today = new Date(date);
  const day = String(today.getUTCDate()).padStart(2, "0");
  const month = String(today.getUTCMonth() + 1).padStart(2, "0");
  const year = today.getUTCFullYear();
  const hours = today.getUTCHours();
  const minutes = today.getUTCMinutes();
  const period = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes.toString().padStart(2, "0");

  return `${day}/${month}/${year} ${formattedHours}:${formattedMinutes} ${period}`;
};
export default formateDate;
