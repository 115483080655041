/** @format */

import { useQuery } from "react-query";
import { reportsService } from "../services/reports";
import { tags } from "../services/tags";
import { TReportParams } from "../types/api";

export const useGetReportProducts = (params: TReportParams) => {
  const query = useQuery(tags.getReportsProducts, async () => {
    const { data } = await reportsService.getReportProducts(params);
    return data.data;
  });
  return { ...query };
};

export const useGetOrderProductsReport = (params: TReportParams) => {
  const query = useQuery([tags.getReportsOrders], async () => {
    const { data } = await reportsService.getOrderProductsReport(params);
    return data.data;
  });
  return { ...query };
};

export const useGetOrdersReport = (params: TReportParams) => {
  const query = useQuery(tags.getReportsOrderDetails, async () => {
    const { data } = await reportsService.getOrdersReport(params);
    return data;
  });
  return { ...query };
};
export const useGetAllReportProducts = (params: TReportParams) => {
  const query = useQuery(
    ["getAllReportsProducts", params.brand_id, params.search, params.date_from],
    async () => {
      const { data } = await reportsService.getReportProducts({
        ...params,
        is_paginate: false,
      });
      return data.data;
    }
  );
  return { ...query };
};

export const useGetAllOrderProductsReport = (params: TReportParams) => {
  const query = useQuery(
    ["getAllReportsOrders", params.brand_id, params.search],
    async () => {
      const { data } = await reportsService.getOrderProductsReport({
        ...params,
        is_paginate: false,
      });
      return data.data;
    }
  );
  return { ...query };
};

export const useGetAllOrdersReport = (params: TReportParams) => {
  const query = useQuery(
    ["getAllReportsOrderDetails", params.brand_id, params.search],
    async () => {
      const { data } = await reportsService.getOrdersReport({
        ...params,
        is_paginate: false,
      });
      return data;
    }
  );
  return { ...query };
};
