import { Divider, Form, FormInstance, Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import clsx from "clsx";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoMailUnreadOutline } from "react-icons/io5";
import { PhoneInput } from "react-international-phone";
import {
  useGetAllCities,
  useGetAllCountries,
  useGetAllRegions,
} from "../../../hooks/lookups";
import { TUser } from "../../../types/auth";
import styles from "./EditDetails.module.scss";

type TProps = {
  form: FormInstance<any>;
  data?: TUser;
};
function EditDetails({ form, data }: TProps) {
  const [address, setAddress] = useState({
    country_id: form.getFieldValue("country_id"),
    region_id: form.getFieldValue("region_id"),
    city_id: form.getFieldValue("city_id"),
  });
  const { t } = useTranslation();
  const [inputPhoneHasError] = useState(false);
  const { data: countries, isLoading: countriesLoading } = useGetAllCountries();

  const { data: regions, isLoading: regionsLoading } = useGetAllRegions();
  const { data: cities, isLoading: citiesLoading } = useGetAllCities();

  const countriesOptions =
    countries?.map((country) => ({
      label: country.name,
      value: country.id,
    })) || [];

  const regionsOptions =
    regions
      ?.filter((region) =>
        address.country_id ? region.country_id === +address.country_id : true
      )
      .map((region) => ({
        label: region.name,
        value: region.id,
      })) || [];

  const citiesOptions =
    cities
      ?.filter((city) =>
        address.region_id ? city.region_id === +address.region_id : true
      )
      .map((country) => ({
        label: country.name,
        value: country.id,
      })) || [];

  console.log({ citiesOptions });
  return (
    <div className={clsx(styles.editDetails, "editDetails")}>
      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label={t("company_name")}
        name="name"
        rules={[
          {
            required: true,
            message: `  ${t("company_name")}  ${t("required")}`,
          },
        ]}
      >
        <Input placeholder={`${t("entered")} ${t("company_name")}  `} />
      </Form.Item>
      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label={`   ${t("company_owner")}/  ${t("responsible_manger")}`}
        name="owner_name"
        rules={[
          {
            required: true,
            message: `${t("company_owner")}/  ${t("responsible_manger")} ${t(
              "required"
            )}`,
          },
        ]}
      >
        <Input
          placeholder={`${t("entered")} ${t("company_owner")}/  ${t(
            "responsible_manger"
          )}  `}
        />
      </Form.Item>
      <Form.Item
        rootClassName={clsx(
          styles.inputGroup,
          styles.phoneInput,
          inputPhoneHasError && styles.error
        )}
        layout="vertical"
        label={t("phone")}
        name="phone"
      >
        <PhoneInput
          name="phone"
          value={form.getFieldValue("phone")}
          defaultCountry="sa"
          onChange={(value, { country }) => {
            // setCountryCode("+" + country.dialCode);
            form.setFieldValue("phone", value);
          }}
        />
      </Form.Item>
      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label={t("email")}
        name="email"
        rules={[{ required: true, message: ` ${t("email")} ${t("required")}` }]}
      >
        <Input
          suffix={<IoMailUnreadOutline />}
          type="email"
          placeholder={`${t("entered")}  ${t("email")}  `}
        />
      </Form.Item>
      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label={t("commercial_register_number")}
        name="commercial_register_number"
        rules={[
          {
            required: true,
            message: ` ${t("commercial_register_number")} ${t("required")}`,
          },
        ]}
      >
        <Input
          placeholder={`${t("entered")} ${t("commercial_register_number")}  `}
        />
      </Form.Item>
      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label={t("tax_card_number")}
        name="tax_card_number"
        rules={[
          {
            required: true,
            message: ` ${t("tax_card_number")} ${t("required")}`,
          },
        ]}
      >
        <Input placeholder={`${t("entered")} ${t("tax_card_number")}  `} />
      </Form.Item>

      <Divider style={{ marginBottom: 0 }} />
      <h3 className={styles.addressTitle}>{t("address")}:</h3>

      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label={t("country")}
        name="country_id"
      >
        <Select
          style={{ width: "100%" }}
          placeholder={`${t("select")} ${t("country")} `}
          options={countriesOptions}
          loading={countriesLoading}
          onChange={(val) => {
            setAddress({ city_id: "", region_id: "", country_id: val });
            form.setFieldValue("country_id", val);
          }}
        />
      </Form.Item>
      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label={t("region")}
        name="region_id"
      >
        <Select
          disabled={!address.country_id}
          style={{ width: "100%" }}
          placeholder={`${t("select")} ${t("region")} `}
          options={regionsOptions}
          loading={regionsLoading}
          onChange={(val) => {
            setAddress({ ...address, city_id: "", region_id: val });
            form.setFieldValue("region_id", val);
          }}
        />
      </Form.Item>
      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label={t("city")}
        name="city_id"
      >
        <Select
          disabled={!address.region_id}
          style={{ width: "100%" }}
          placeholder={`${t("select")} ${t("city")} `}
          options={citiesOptions}
          loading={citiesLoading}
          onChange={(val) => {
            setAddress({ ...address, city_id: val });
            form.setFieldValue("city_id", val);
          }}
        />
      </Form.Item>
      <Form.Item
        rootClassName={styles.inputTextArea}
        layout="vertical"
        label={t("company_address_details")}
        name="address"
        rules={[
          {
            required: true,
            message: ` ${t("company_address_details")} ${t("required")}`,
          },
        ]}
      >
        <TextArea
          rootClassName={styles.textArea}
          placeholder={`${t("entered")} ${t("company_address_details")}  `}
        />
      </Form.Item>
    </div>
  );
}

export default EditDetails;
