import { TOrderDetails } from "../../../types/order";
import styles from "./InvoiceClient.module.scss";

type TProps = {
  data?: TOrderDetails;
};
function InvoiceClient({ data }: TProps) {
  const items = [
    {
      id: 1,
      nameEn: "Customer Name",
      nameAr: "اسم العميل",
      value: data?.order_details?.owner?.name,
    },
    {
      id: 1,
      nameEn: "Customer Name",
      nameAr: "عنوان العميل",
      value: `${
        data?.order_details?.owner?.seller_address?.country?.name || ""
      }, ${data?.order_details?.owner?.seller_address?.city?.name || ""}`,
    },
    {
      id: 1,
      nameEn: "Customer TIN",
      nameAr: " الرقم الضريبي للعميل",
      value: data?.order_details?.owner?.commercial_register_number,
    },
    {
      id: 1,
      nameEn: "Customer ID",
      nameAr: "معرف  العميل",
      value: data?.order_details?.owner?.id,
    },
  ];

  return (
    <div className={styles.invoiceClient}>
      <h3 className={styles.title}>بيانات العميل &nbsp; Customer Info. </h3>
      <ul className={styles.list}>
        {items.map((item) => (
          <li key={item.id} className={styles.item}>
            <div className={styles.label}>
              <p>{item.nameAr}</p>
              <p>{item.nameEn} </p>
            </div>
            <p>:</p>
            <div className={styles.value}>{item.value}</div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default InvoiceClient;
