/** @format */

import clsx from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useNewSubCategory } from "../../../hooks/store";
import { TCategory, TMainCategory } from "../../../types/store";
import PaginationCom from "../../Common/PaginationComp";
import { BrandCard } from "../Brands";
import style from "./style.module.scss";
export type TFilters = { category_filter: string; name: string; page: string };
export default function CategoryComp() {
  const { t } = useTranslation();
  const { brandId, categoryId } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<TFilters>({
    category_filter: categoryId || "",
    name: searchParams.get("name") || "",
    page: searchParams.get("page") || "1",
  });
  // const getFilterChanges = (stateName: string, value: string) => {
  //   setSearchParams({
  //     ...filters,
  //     [stateName]: value ? value.toString() : "",
  //     page: "1",
  //   });
  //   setFilters({ ...filters, [stateName]: value, page: "1" });
  // };
  useEffect(() => {
    setFilters({ ...filters, category_filter: categoryId || "" });
  }, [categoryId]);
  const { page } = filters;
  const gatPage = (page: number) => {
    setSearchParams({ ...filters, page: page.toString() });
    setFilters({ ...filters, page: page.toString() });
  };
  const { data } = useNewSubCategory(filters, Number(page));
  return (
    <div className={style.categoriesCont}>
      <div className={style.titleCont}>
        <h4>{t("select_store")}:</h4>
        <div className={clsx(style.paginationCont, "paginationCont")}>
          <PaginationCom
            setPage={gatPage}
            currentPage={Number(page)}
            total={data?.total}
          />
          <p>
            {t("show")} {(Number(page) - 1) * 10 + 1} {t("to")}{" "}
            {Number(page) * 10} {t("from")} {data?.total} {t("brand")}
          </p>
        </div>
      </div>

      <div className={style.cardsCont}>
        {data ? (
          <>
            {data.data.map((category: TMainCategory) =>
              data.brand ? (
                <BrandCard
                  brand={category.brand}
                  key={category.id}
                  childCount={category.child_count}
                  categoryName={category.parent?.name || ""}
                  category_id={category.id || category.parent?.id}
                />
              ) : (
                <CategoryCard category={category} key={category.id} />
              )
            )}
          </>
        ) : null}
      </div>
    </div>
  );
}
const CategoryCard = ({ category }: { category: TCategory }) => {
  const { brandId } = useParams();
  const prepareBrands = () => {
    if (category.child_count !== 0) {
      return `/store/brand/${brandId}/sub-category/${category.id}`;
    } else {
      return `/store/brand/${brandId}/category/${category.id}`;
    }
  };
  return (
    <Link to={prepareBrands()}>
      <div className={style.card}>
        <div className={style.imgCont}>
          <img src={category.image} alt={category.name} />
        </div>
        <h5>{category.name}</h5>
      </div>
    </Link>
  );
};
