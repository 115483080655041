/** @format */

import { useTranslation } from "react-i18next";
import CustomSkelton from "../../../components/CustomSkelton/CustomSkelaton";
import EditProfileForm from "../../../components/profile/EditProfileForm/EditProfileForm";
import { useGetProfile } from "../../../hooks/profile";

function EditProfile() {
  const { data, isLoading } = useGetProfile();
  const { t } = useTranslation();
  document.title = t("edit_profile");
  if (isLoading) return <CustomSkelton />;
  return <EditProfileForm data={data} isLoading={isLoading} />;
}

export default EditProfile;
