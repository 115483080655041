/** @format */

import { useTranslation } from "react-i18next";
import SignUpLottie from "../../../assets/lottie/lottie-sign-up.json";
import AuthLayout from "../../../components/auth/AuthLayout/AuthLayout";
import GoogleAuthVerify from "../../../components/auth/Forms/GoogleAuthVerify/GoogleAuthVerify";

function Page() {
  const { t } = useTranslation();
  document.title = t("google_auth");
  return (
    <AuthLayout lottie={SignUpLottie}>
      <GoogleAuthVerify />
    </AuthLayout>
  );
}
export default Page;
