/** @format */

import { Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import { CiSearch } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { TFilters } from "..";
import { TMainCategory } from "../../../../types/store";
import style from "../style.module.scss";

export default function BrandFilter({
  mainCategories,
  filters,
  getFilterChanges,
}: {
  mainCategories?: TMainCategory[];
  filters: TFilters;
  getFilterChanges: (value: string, stateName: string) => void;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className={style.filterCon}>
      <h5>القسم</h5>
      <div>
        <Select
          placeholder={t("all_sections")}
          allowClear
          style={{ width: "100%" }}
          onChange={(event) => getFilterChanges("category_filter", event)}
          options={[
            {
              label: <span>{t("all_sections")}</span>,
              title: t("all_sections"),
              options: mainCategories
                ? mainCategories.map((mainCategory) => ({
                    label: <span>{mainCategory.name}</span>,
                    value: mainCategory.id,
                  }))
                : [],
            },
          ]}
          showSearch
        />
        <Input
          onChange={(event) => getFilterChanges("name", event.target.value)}
          suffix={<CiSearch />}
          placeholder={t("search_for_brands")}
        />
      </div>
    </div>
  );
}
