/** @format */

import { useTranslation } from "react-i18next";
import ViewProfileContent from "../../../components/profile/ViewProfileContent/ViewProfileContent";

function ViewProfile() {
  const { t } = useTranslation();
  document.title = `${t("settings")} | ${t("profile")}`;
  return <ViewProfileContent />;
}

export default ViewProfile;
