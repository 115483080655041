/** @format */

import { TableProps } from "antd";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import formateDate from "../../../utils/formateDate.";
import CustomTable from "../../Common/CustomTable/CustomTable";
import styles from "./Orders.module.scss";

type TProps = {
  data?: any;
  isLoading?: boolean;
  handlePagination: (page: number) => void;
};
function Orders({ data, isLoading, handlePagination }: TProps) {
  const { t } = useTranslation();
  const columns: TableProps<any>["columns"] = useMemo(
    () => [
      {
        title: t("added_date"),
        dataIndex: "order_date",
        key: "order_date",
        render: (val) => <p>{formateDate(val)}</p>,
      },
      {
        title: t("order_no"),
        dataIndex: "order_number",
        key: "orderNuorder_numbermber",
        render: (val) => <p>{val}</p>,
      },
      {
        title: t("created_by"),
        dataIndex: "created_by",
        key: "created_by",
        render: (val) => <b>{val}</b>,
      },
      {
        title: t("card_no"),
        dataIndex: "products_count",
        key: "products_count",
      },
      {
        title: t("total"),
        dataIndex: "total",
        key: "total",
        render: (val) => <b>{val}</b>,
      },
    ],
    []
  );

  return (
    <div className={styles.orders}>
      <CustomTable
        columns={columns}
        className={styles.customTable}
        loading={isLoading}
        dataSource={data?.data}
        configStyle={{
          fontWeightStrong: 500,
          headerColor: "#878A99",
          padding: 10,
        }}
      />
    </div>
  );
}

export default Orders;
