/** @format */

import { useTranslation } from "react-i18next";
import SignUpLottie from "../../../assets/lottie/lottie-sign-up.json";
import AuthLayout from "../../../components/auth/AuthLayout/AuthLayout";
import SignUpForm from "../../../components/auth/Forms/SignupForm/SignupForm";
function Page() {
  const { t } = useTranslation();

  document.title = t("sign_up");
  return (
    <AuthLayout lottie={SignUpLottie}>
      <SignUpForm formHeading={t("create_new_account")} />
    </AuthLayout>
  );
}
export default Page;
