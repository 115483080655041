/** @format */

import { AxiosResponse } from "axios";
import {
  TPagination,
  TPaginationResponse,
  TReportParams,
  TReportResponse,
} from "../types/api";
import { TOrderReport, TReportOrderProducts } from "../types/order";
import { TReportProduct } from "../types/products";
import api from "./api";

export const reportsService = {
  getReportProducts: async (
    params: TReportParams
  ): Promise<AxiosResponse<TReportResponse<TReportProduct[]>>> =>
    api.get("/products-report", { params }),
  getOrderProductsReport: async (
    params: TReportParams
  ): Promise<AxiosResponse<TPaginationResponse<TReportOrderProducts[]>>> =>
    api.get("/order-products-report", { params }),
  getOrdersReport: async (
    params: TReportParams
  ): Promise<AxiosResponse<TPaginationWithTotalResponse<TOrderReport[]>>> =>
    api.get("/orders-report", { params }),
};

export type TPaginationWithTotalResponse<T> = {
  code: number;
  status: boolean;
  message: string;
  data: {
    data: {
      current_page: number;
      data: T;
      total: number;
      last_page: number;
      links: TPagination[];
      per_page: number;
    };
    totals: {
      totalOrders: number;
      totalProductsCount: number;
    };
  };
};
