import { useQueryClient } from "react-query";
import { tags } from "../services/tags";

const useValidateAllTags = () => {
  const queryClient = useQueryClient();

  const validateAllTags = () => {
    Object.keys(tags)?.forEach((key) =>
      queryClient.invalidateQueries(tags[key as keyof typeof tags])
    );
  };
  return { validateAllTags };
};

export default useValidateAllTags;
