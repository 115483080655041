/** @format */

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Input, Select } from "antd";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useGetAllOrdersIds } from "../../../hooks/order";
import { useAddTicket } from "../../../hooks/useTicket";

import ImageUploader from "../../Common/ImageUploader";
import style from "./style.module.scss";
const schema = yup.object().shape({
  title: yup.string().required("Title is required"),
  order_id: yup.number().required("Order id is required"),
  details: yup.string().required("Details is required"),
  attachments: yup.mixed().required("Image is required"),
  error_message: yup.string().required("Error mmessage is required"),
  // .test(
  //   "يجب أن يكون receipt image ملف من النوع: jpeg, png, jpg, pdf.",
  //   (value) => {
  //     return (
  //       value &&
  //       value instanceof File &&
  //       ["image/jpeg", "image/png", "image/jpg", "image/pdf"].includes(
  //         value.type
  //       )
  //     );
  //   }
  // ),
});
const { TextArea } = Input;
export const errorMsgs = [
  { label: "already_used", value: "already_used" },
  { label: "incorrect_code", value: "code_incorrect" },
  { label: "inactive", value: "inefficient" },
  { label: "other", value: "other" },
];
export default function AddTicketForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [attachments, setImage] = useState<string | null>(null);
  // const { data: tickets } = useGitTicket();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { data, mutate, isLoading } = useAddTicket(navigate);
  const { data: orderIds } = useGetAllOrdersIds();
  const onSubmit = async (data: any) => {
    const formData = new FormData();

    Object.keys(data).forEach((key: any) => {
      formData.append(key, data[key]);
    });

    await mutate(formData);
  };
  const saveImage = (image: string | null, file: File) => {
    setValue("attachments", file);
    setImage(image);
  };
  return (
    <div className={style.cont}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.cashCont}>
          <div className={style.formCont}>
            <div className={style.form}>
              <div>
                <label>{t("ticket_title")}</label>

                <Controller
                  name='title'
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder={t("enter_the_title")}
                      size='large'
                      {...field}
                    />
                  )}
                />
                {errors.title?.message ? (
                  <span className={style.error}>{errors.title?.message}</span>
                ) : null}
              </div>
              <div>
                <label>{t("order_num_options")}</label>

                <Controller
                  name='order_id'
                  control={control}
                  render={({ field }) => (
                    <Select
                      //   showSearch
                      size='large'
                      style={{ width: "100%" }}
                      options={[
                        {
                          label: <span>{t("enter_order_num")}</span>,
                          title: "manager",
                          options: orderIds?.map((ticket) => ({
                            label: `#${t(ticket)}`,
                            value: ticket,
                          })),
                        },
                      ]}
                      {...field}
                      placeholder={t("enter_order_num")}
                      showSearch
                    />
                  )}
                />
                {errors.order_id?.message ? (
                  <span className={style.error}>
                    {errors.order_id?.message}
                  </span>
                ) : null}
              </div>
              <div>
                <label>{t("ticket_details")}</label>
                <Controller
                  name='details'
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      {...field}
                      rows={4}
                      placeholder={t("add_detaild_ticket")}
                    />
                  )}
                />
                {errors.order_id?.message ? (
                  <span className={style.error}>
                    {errors.order_id?.message}
                  </span>
                ) : null}
              </div>
              <div>
                <label>{t("error_msg")}</label>
                <Controller
                  name='error_message'
                  control={control}
                  render={({ field }) => (
                    <Select
                      onChange={(event) => setValue("error_message", event)}
                      placeholder={t("error_msg")}
                      options={errorMsgs}
                    />
                  )}
                />
                {errors.error_message?.message ? (
                  <span className={style.error}>
                    {errors.error_message?.message}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div className={style.receiptCont}>
            <p> {t("upload_attachments")}</p>
            <ImageUploader setImage={saveImage} image={attachments} />
            {errors.attachments?.message ? (
              <span className={style.error}>{errors.attachments?.message}</span>
            ) : null}
          </div>
        </div>
        <Button
          loading={isLoading}
          variant='filled'
          htmlType='submit'
          color='default'>
          {t("send")}
        </Button>
      </form>
    </div>
  );
}
