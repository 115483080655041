/** @format */
import { Table, TableProps } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useGetProfile } from "../../../hooks/profile";
import { THomeTransactions } from "../../../types/products";
import style from "./style.module.scss";
export default function TransactionsTable({
  transactions,
}: {
  transactions: THomeTransactions[];
}) {
  const { t } = useTranslation();
  const { data: profile } = useGetProfile();
  const columns: TableProps<THomeTransactions>["columns"] = [
    {
      title: t("date"),
      dataIndex: "created_at",
      key: "created_at",
      render: (text: string) => (
        <p className={style.createdAt}>
          {dayjs(text).format("DD-MMMM-YYYY HH:mm a")}
        </p>
      ),
      onHeaderCell: () => ({
        style: {
          fontSize: "14px",
        },
      }),
    },
    {
      title: t("transactionsNum"),
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("bank"),
      dataIndex: "bank",
      key: "bank",
      render: (_, record) => <b>{record.bank.name}</b>,
    },
    {
      title: t("senderName"),
      key: "seller",
      dataIndex: "seller",
      render: (_, record) => <span>{record.seller.name}</span>,
    },
    {
      title: t("amount"),
      key: "amount",
      dataIndex: "amount",
      render: (text: number) => (
        <b className={style.amount}>
          {text.toFixed(3)}
          <span> {profile?.currency?.name || "ر.س"}</span>
        </b>
      ),
    },
  ];
  return (
    <div>
      <Table<THomeTransactions>
        className={style.balanceTable}
        columns={columns}
        pagination={false}
        dataSource={transactions}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
}
